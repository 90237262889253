import PrivateRoute from "@/privateRoute";
import EditProfile from ".";

const routes = [
  {
    path: "/profile/edit",
    element: (
      <PrivateRoute>
        <EditProfile />
      </PrivateRoute>
    ),
    title: "Edit Profile"
  }
];

export default routes;
