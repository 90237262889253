import { lazy } from 'react';

const Tutorial = lazy(() => import('./Tutorial'));
const ValuePropositions = lazy(() => import('./ValuePropositions'));
const ProfileBasic = lazy(() => import('./ProfileBasic'));
const ProfileFieldsAndLevels = lazy(() => import('./ProfileFieldsAndLevels'));
const ProfileProfessionalHistoryAndSkillsets = lazy(() =>
  import('./ProfileProfessionalHistoryAndSkillsets')
);
const ExchangeLinkContactInfo = lazy(() => import('./ExchangeLinkContactInfo'));
const ExchangeLinkDashboard = lazy(() => import('./ExchangeLinkDashboard'));
const ContactManagementDashboard = lazy(() =>
  import('./ContactManagementDashboard')
);
const ContactManagementContact = lazy(() =>
  import('./ContactManagementContact')
);
const DynamicFeatureNotes = lazy(() => import('./DynamicFeatureNotes'));
const DynamicFeatureTags = lazy(() => import('./DynamicFeatureTags'));
const DynamicFeatureMultiSelect = lazy(() =>
  import('./DynamicFeatureMultiSelect')
);
const DynamicFeatureContactLogs = lazy(() =>
  import('./DynamicFeatureContactLogs')
);

export {
  Tutorial,
  ValuePropositions,
  ProfileBasic,
  ProfileFieldsAndLevels,
  ProfileProfessionalHistoryAndSkillsets,
  ExchangeLinkContactInfo,
  ExchangeLinkDashboard,
  ContactManagementDashboard,
  ContactManagementContact,
  DynamicFeatureNotes,
  DynamicFeatureTags,
  DynamicFeatureMultiSelect,
  DynamicFeatureContactLogs
};
