import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import VIEW from '@/fixtures/view';

const getCurrentView = async () => {
  const data = await new Promise(resolve => {
    const item = localStorage.getItem('currentView') || JSON.parse(VIEW.grid);
    resolve(item);
  });

  return data;
};

export const useGetCurrentView = () => {
  return useQuery({
    queryKey: ['currentView'],
    queryFn: getCurrentView,
    initialData: VIEW.grid
  });
};

const updateCurrentView = async data => {
  const response = await new Promise(resolve => {
    localStorage.setItem('currentView', data);
    resolve(data);
  });

  return response;
};

export const useUpdateCurrentView = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCurrentView, {
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['currentView'] });
    }
  });
};
