import React, { lazy } from 'react';

import PrivateRoute from '@/privateRoute';

const ExchangeFields = lazy(() => import('.'));
const FieldsTab = lazy(() => import('./components/FieldsTab'));
const LevelsTab = lazy(() => import('./components/LevelsTab'));

const routes = [
  {
    path: '/exchange-fields/edit',
    title: 'Exchange Fields and Levels',
    element: (
      <PrivateRoute>
        <ExchangeFields />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        title: 'Exchange Fields and Levels - Fields',
        element: (
          <PrivateRoute>
            <FieldsTab />
          </PrivateRoute>
        )
      },
      {
        path: 'fields',
        title: 'Exchange Fields and Levels - Fields',
        element: (
          <PrivateRoute>
            <FieldsTab />
          </PrivateRoute>
        )
      },
      {
        path: 'levels',
        title: 'Exchange Fields and Levels - Levels',
        element: (
          <PrivateRoute>
            <LevelsTab />
          </PrivateRoute>
        )
      }
    ]
  }
];

export default routes;
