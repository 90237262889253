import format from 'date-fns/format';

const timeFormatter = (time = new Date()) => {
  const parsedTime = new Date(time);
  const minuteOffset = parsedTime.getTimezoneOffset();
  const hourOffset = minuteOffset / 60;
  const sign = hourOffset <= 0 ? '+' : '-';

  return `${format(parsedTime, 'hh:mm')} UTC ${sign}${Math.abs(hourOffset)}`;
};

export default timeFormatter;
