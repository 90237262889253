import React, { lazy } from 'react';

import PrivateRoute from '@/privateRoute';

const TriangleIntroduction = lazy(() => import('.'));

const routes = [
  {
    path: '/triangle-introduction',
    title: 'Triangle Introduction',
    element: (
      <PrivateRoute>
        <TriangleIntroduction />
      </PrivateRoute>
    )
  }
];

export default routes;
