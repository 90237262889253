import React, { lazy } from 'react';

const PhotoUpload = lazy(() => import('.'));

const routes = [
  {
    path: '/photo_upload',
    element: <PhotoUpload />,
    title: 'Photo Upload'
  }
];

export default routes;
