import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import clsx from 'clsx';
import { isBrowser } from 'react-device-detect';

import { useUploadBusinessLogo } from '@/api/users';

import UploadButtons from '@/components/UploadButtons';
import Accordion from '@/components/Accordion';
import ToastNotification from '@/components/ToastNotification';

import './styles.scss';

const HexShape = ({ image = '', isLoading = false }) => {
  return (
    <div className={clsx('hex-container', isLoading && '-animate')}>
      {image ? (
        <img className='img' src={image} alt='logo' />
      ) : (
        <div className='inner'>
          <div className='content' />
        </div>
      )}
    </div>
  );
};

const BusinessLogoUpload = ({ userId, logo, isActive = false }) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { mutate: uploadBusinessLogo, isLoading: isUploading } =
    useUploadBusinessLogo();

  const handleUploadChange = ({ file }) => {
    if (file.status === 'done') {
      const imageObj = file.originFileObj;

      handleSubmit(imageObj);
    }
  };

  const handleCropOpen = () => {
    const link = isBrowser
      ? '/contact-management/profile/edit/crop'
      : '/profile/edit/crop';

    navigate(link, {
      state: {
        image: 'business_logo_url'
      }
    });
  };

  const handleSubmit = imageObj => {
    const payload = new FormData();
    payload.append('business_logo', imageObj);

    const data = {
      id: userId,
      data: payload
    };

    uploadBusinessLogo(data, {
      onSuccess: () => {
        addToast(
          <ToastNotification
            type='Success'
            message='Successfully uploaded the business logo!'
          />
        );
      },
      onError: err => {
        addToast(
          <ToastNotification
            type='Error'
            message='Unable to upload a business logo. Please try again.'
          />
        );
        console.error(err);
      }
    });
  };

  return (
    <Accordion active={isActive}>
      <Accordion.ListHeader>Upload Business Icon</Accordion.ListHeader>
      <Accordion.Body>
        <div className='business-logo-upload'>
          <div className='img-container'>
            {isUploading ? (
              <HexShape isLoading={isUploading} />
            ) : (
              <HexShape image={logo} />
            )}
          </div>
          {!isUploading && (
            <div className='btn-container'>
              <UploadButtons
                onUploadChange={handleUploadChange}
                isUploading={isUploading}
                hasUploadValue={logo}
                onCropOpen={handleCropOpen}
              />
            </div>
          )}
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

export default BusinessLogoUpload;
