const parseContact = contact => {
  return {
    avatarUrl: contact?.avatar_url,
    cardPhotoUrl: contact?.card_photo_url,
    businessLogoUrl: contact?.business_logo_url,
    company: contact?.company || '',
    fields: contact?.fields || [],
    firstName: contact?.first_name || '',
    lastName: contact?.last_name || '',
    middleName: contact?.middle_name || '',
    notes: contact?.notes || [],
    suffix: contact?.suffix || '',
    tags: contact?.tags || [],
    titlePosition: contact?.title_position || '',
  };
};

export default parseContact;
