import { Navigate } from 'react-router-dom';
import { Begin, Welcome, Roadmap, Commitments, FAQ, Goals, NDA } from '.';

const routes = [
  {
    path: '/onboarding',
    element: <Navigate to='/onboarding/begin' replace={true} />,
    title: 'Beta - Beginning'
  },
  {
    path: '/onboarding/begin',
    element: <Begin />,
    title: 'Beta - Beginning'
  },
  {
    path: '/onboarding/welcome',
    element: <Welcome />,
    title: 'Beta - Welcome'
  },
  {
    path: '/onboarding/product-roadmap',
    element: <Roadmap />,
    title: 'Beta - Road Map'
  },
  {
    path: '/onboarding/commitments',
    element: <Commitments />,
    title: 'Beta - Commitments'
  },
  {
    path: '/onboarding/faq',
    element: <FAQ />,
    title: 'Beta - Frequently Asked Questions'
  },
  {
    path: '/onboarding/goals',
    element: <Goals />,
    title: 'Beta - Goals'
  },
  {
    path: '/onboarding/nda',
    element: <NDA />,
    title: 'Beta - Non Disclosure Agreement'
  }
];

export default routes;
