import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';

import Icon from '../Icon';

const Action = ({ children = <></>, className = '' }) => {
  const { removeAllToasts } = useToasts();

  const handleClick = event => {
    event.preventDefault();
    removeAllToasts();
  };

  return (
    <div className={clsx('action', className)}>
      <Icon name='close' className='close-btn' onClick={handleClick} />
      {children}
    </div>
  );
};

Action.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Action;
