import React from 'react';
import clsx from 'clsx';
import './styles.scss';

// We're not using antd Icon since it has a different look
const Icon = ({ name, className, onClick, style }) => {
  const iconClassNames = clsx(
    'material-symbols-outlined',
    className,
    onClick && '-button'
  );

  return (
    <span className={iconClassNames} onClick={onClick} style={style}>
      {name}
    </span>
  );
};

export default Icon;
