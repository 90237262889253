// temporary solution for tile hive
const GRID_COLUMNS_MULTIPLIER = {
  1: [0, 0],
  2: [21.5, 12.5],
  3: [14.3, 8.3],
  4: [10.75, 6.2],
  5: [8.6, 5],
  6: [7.1, 4.1],
  7: [6.1, 3.6],
  8: [5.4, 3.2]
};

export default GRID_COLUMNS_MULTIPLIER;
