import React, { useCallback } from 'react';

import { Editable } from 'slate-react';

import Element from './Element';
import Leaf from './Leaf';
import { Editor } from 'slate';

const PREVENT_INPUT = ['insertParagraph', 'insertText', 'insertFromPaste'];
const DEFAULT_MAX_LENGTH = 200;

const Form = ({
  placeholder = '',
  readOnly,
  maxLength = DEFAULT_MAX_LENGTH,
  editor
}) => {
  const renderElement = useCallback(props => {
    return <Element {...props} />;
  }, []);

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />;
  }, []);

  const handleDOMBeforeInput = event => {
    const inputType = event.inputType;

    if (PREVENT_INPUT.includes(inputType)) {
      const textLength = Editor.string(editor, []).length;
      if (maxLength > 0 && textLength >= maxLength) {
        event.preventDefault();
        return;
      }
    }
  };

  const handlePaste = event => {
    event.preventDefault();
    const paste = (event.clipboardData || window.Clipboard).getData("text");
    const textLength = Editor.string(editor, []).length;

    let newPaste = paste;
    if(textLength + paste.length >= maxLength) {
      const trimSize = maxLength - textLength
      newPaste = paste.substring(0, trimSize);
    }
    if(newPaste.length != 0) editor.insertText(newPaste);

  }

  return (
    <Editable
      className='textarea'
      placeholder={placeholder}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      readOnly={readOnly}
      onPaste={handlePaste}
      onDOMBeforeInput={handleDOMBeforeInput}
    />
  );
};

export default Form;
