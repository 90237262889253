import React from 'react';
import PropTypes from 'prop-types';

import TagEditOption from '../TagEditOption';

import { useToggle } from '@/hooks';

import './styles.scss';

const TagsListEdit = ({ onDelete = () => {}, tags = [] }) => {
  const [isOpen, { setFalse: close, toggle }] = useToggle();

  return (
    <div className='tag-edit'>
      <div className='heading'>
        <div className='label'>
          <span className='title'>
            Edit All Tags
            <span className='material-symbols-outlined icon' onClick={toggle}>
              help
            </span>
          </span>
          {isOpen && (
            <div className='info'>
              <li>Drag to set manual order</li>
              <li>Select Color</li>
              <li>Long press on text to change tag label</li>
              <li>Press Trashcan to delete tag from your system</li>
              <span className='material-symbols-outlined close' onClick={close}>
                expand_less
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='lists'>
        {tags.map(tag => (
          <TagEditOption tag={tag} onDelete={onDelete} key={tag.id} />
        ))}
      </div>
    </div>
  );
};

TagsListEdit.propTypes = {
  onDelete: PropTypes.func,
  tags: PropTypes.array
};

export default TagsListEdit;
