import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

const UploadVCF = lazy(() => import('.'));

const routes = [
  {
    path: '/upload-vcf',
    element: (
      <PrivateRoute>
        <UploadVCF />
      </PrivateRoute>
    ),
    title: 'Upload VCF'
  }
];

export default routes;
