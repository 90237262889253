import React from 'react';

import { getImageUrl } from '@/lib/helpers/getImageUrl';

import defaultAvatar from '@/assets/images/default-avatar.png';

const Image = ({ defaultValue = defaultAvatar, src, alt = 'Tile' }) => (
  <img className='img' src={src ? getImageUrl(src) : defaultValue} alt={alt} />
);

export default Image;
