import React, { useEffect, useState } from 'react';
import { useToggle } from '@/hooks';

import ButtonIcon from '../ButtonIcon';

import './styles.scss';

const LevelDropdown = ({
  levels,
  name,
  register,
  setValue,
  trigger,
  defaultValue
}) => {
  const [isOpen, { toggle }] = useToggle();
  const [level, setLevel] = useState(null);

  useEffect(() => {
    register(name, { required: true });
  }, []);

  useEffect(() => {
    if (levels && defaultValue) {
      const level = levels.find(level => level.id === defaultValue);
      setValue(name, defaultValue);
      setLevel(level);
    }
  }, [defaultValue, levels]);

  const handleToggleClick = () => {
    toggle();
  };

  const handleSelect = level => {
    setLevel(level);
    setValue(name, level.id);
    trigger(name);
    toggle();
  };

  return (
    <div className={'level-dropdown'}>
      <div className='heading'>
        {level ? (
          <p className='-selected'>{level.name}</p>
        ) : (
          <p><i>--select exchange level--</i></p>
        )}

        <div className='button-container' onClick={handleToggleClick}>
          {isOpen ? (
            <ButtonIcon
              icon='expand_less'
              color={isOpen ? 'deep-sapphire' : 'white'}
              className='icontainer -active'
            />
          ) : (
            <ButtonIcon
              icon='expand_more'
              color={isOpen ? 'deep-sapphire' : 'white'}
              className='icontainer'
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className='level-menu'>
          {levels.map((level, idx) => (
            <div key={idx} className='list' onClick={() => handleSelect(level)}>
              {idx + 1}. {level.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LevelDropdown;
