import React, { lazy } from 'react';

const Welcome = lazy(() => import('./Welcome'));
const IntroSynthnode = lazy(() => import('./IntroSynthnode'));
const IntroContactManagement = lazy(() => import('./IntroContactManagement'));
const AddingContact = lazy(() => import('./AddingContact'));
const DynamicFuncContactFields = lazy(() => import('./DynamicFuncContactFields'));
const DynamicFuncTags = lazy(() => import('./DynamicFuncTags'));
const DynamicFuncNotes = lazy(() => import('./DynamicFuncNotes'));
const DynamicFuncContactLog = lazy(() => import('./DynamicFuncContactLog'));
const DynamicMenuTag = lazy(() => import('./DynamicMenuTag'));
const DynamicMenuContactLog = lazy(() => import('./DynamicMenuContactLog'));

export {
  Welcome,
  IntroSynthnode,
  IntroContactManagement,
  AddingContact,
  DynamicFuncContactFields,
  DynamicFuncTags,
  DynamicFuncNotes,
  DynamicFuncContactLog,
  DynamicMenuTag,
  DynamicMenuContactLog
};
