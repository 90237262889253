import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import DatePicker from 'antd/es/date-picker';
import TimePicker from 'antd/es/time-picker';
import { Controller } from 'react-hook-form';

import './styles.scss';

const components = {
  date: DatePicker,
  time: TimePicker
};

const DatePickerField = ({
  className,
  control,
  name,
  type = 'date',
  ...props
}) => {
  const classNames = clsx('date-picker', className);
  const Picker = components[type];

  return (
    <div className={classNames}>
      <Controller
        name={name}
        control={control}
        render={
          ({ field }) => {
            return (
              <Picker 
                size='large'
                {...field}
                {...props}
                />
            );
          }
        }
      />
    </div>
  );
}

DatePickerField.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'time']),
};

export default DatePickerField;
