import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Icon } from '@/components';
import { ToggleContext } from './Accordion';
import Header from './Header';

const ListHeader = ({ className = '', callback = () => {}, children }) => {
  const { disabled, isActive } = useContext(ToggleContext);

  return (
    <Header className={className} callback={callback}>
      <div className='label'>{children}</div>

      {!disabled && (
        <div className={clsx('icon')}>
          {isActive ? <Icon name='expand_less' /> : <Icon name='expand_more' />}
        </div>
      )}
    </Header>
  );
};

ListHeader.propTypes = {
  actionIcon: PropTypes.string,
  className: PropTypes.string,
  expandIconPosition: PropTypes.oneOf(['start', 'end']),
  onClickAction: PropTypes.func
};

export default ListHeader;
