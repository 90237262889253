import React, { lazy } from 'react';
import PublicRoute from '@/publicRoute';

const SignIn = lazy(() => import('.'));

const routes = [
  {
    path: '/login',
    element: (
      <PublicRoute>
        <SignIn />
      </PublicRoute>
    ),
    title: 'SignIn'
  }
];
export default routes;
