import React, { lazy } from 'react';
import PublicRoute from '@/publicRoute';

const SignUp = lazy(() => import('.'));

const routes = [
  {
    path: '/signup',
    element: (
      <PublicRoute>
        <SignUp />
      </PublicRoute>
    ),
    title: 'Sign Up'
  }
];

export default routes;
