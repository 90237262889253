import React, { forwardRef } from 'react';
import clsx from 'clsx';

import './styles.scss';

const Container = forwardRef(({ children, className, ...props }, ref) => {
  const classNames = clsx('hex-tile-container', className);

  return (
    <div ref={ref} className={classNames} {...props}>
      {children}
    </div>
  );
});

export default Container;
