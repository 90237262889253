import React, { useEffect, useState } from 'react';

import { useGetCurrentSound } from '@/api/currentSound';
import { useGetNotifications } from '@/api/notifications';

import { Badge } from 'antd';
import { BellFilled } from '@ant-design/icons';

import notification from '@/assets/audio/notification.mp3';

const NotificationIcon = ({ onClick }) => {
  const { data: notifications } = useGetNotifications();
  const { data: currentSound } = useGetCurrentSound();

  const [prevData, setPrevData] = useState(notifications);
  const [currData, setCurrData] = useState(notifications);

  useEffect(() => {
    if (notifications) {
      setPrevData(currData);
      setCurrData(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    const audio = new Audio(notification);

    if (currentSound === 'on' && prevData?.length < currData?.length) {
      audio.play();
    }

    return () => audio.pause();
  }, [currentSound, prevData, currData]);

  return (
    <Badge count={notifications?.length}>
      <BellFilled onClick={onClick} className='bell-icon' />
    </Badge>
  );
};

export default NotificationIcon;
