import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

const Components = lazy(() => import('.'));

const routes = [
  {
    path: '/components',
    element: (
      <PrivateRoute>
        <Components />
      </PrivateRoute>
    ),
    title: 'Components'
  }
];

export default routes;
