import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PasswordStrengthBar from 'react-password-strength-bar';
import PropTypes from 'prop-types';

import './styles.scss';

const InputField = ({
  label,
  inputType,
  type,
  name,
  inputRegister,
  register,
  registerOptions,
  error,
  optional,
  getValues,
  showError = true,
  errors = [],
  onValueChange = () => {},
  registerCallback = () => {},
  onChange, // New onChange prop
  ...props
}) => {
  const defaultValue = useRef('');
  const [text, setText] = useState(defaultValue.current);
  const [showPassword, setShowPassword] = useState(false);
  const [getGetValues, setGetGetValues] = useState(getValues);

  const inputClassName = clsx(
    'input',
    optional ? '-optional' : '-required',
    getGetValues === null ||
      getGetValues === undefined ||
      getGetValues === '' ||
      text === ''
      ? '-empty'
      : '-non-empty'
  );

  const handleChange = event => {
    const value = event.target.value;
    setText(value);
    onValueChange(value);
    setGetGetValues(value);
    // Call the onChange prop if it's provided
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className='input-field'>
      {label && <label className='label'>{label}</label>}
      {inputType === 'password-register' && (
        <div className='password-container'>
          <div className='password'>
            <input
              type={showPassword ? 'text' : 'password'}
              className={inputClassName}
              {...register(name, {
                onChange: event => {
                  handleChange(event);
                  registerCallback();
                }
              })}
              maxLength={100}
              {...props}
            />
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
              style={{
                color: text !== '' ? '#0C2E78' : 'white'
              }}
            >
              <span className='material-symbols-outlined'>
                {showPassword ? 'visibility_off' : 'visibility'}
              </span>
            </button>
          </div>
          {inputRegister && (
            <PasswordStrengthBar
              password={text}
              className='password-strength'
            />
          )}
        </div>
      )}
      {inputType === 'password' && (
        <div className='password'>
          <input
            type={showPassword ? 'text' : 'password'}
            className={inputClassName}
            onChange={handleChange}
            maxLength={100}
            {...props}
          />
          <button
            type='button'
            onClick={() => setShowPassword(!showPassword)}
            style={{
              color: text !== '' ? '#0C2E78' : 'white'
            }}
          >
            <span className='material-symbols-outlined'>
              {showPassword ? 'visibility_off' : 'visibility'}
            </span>
          </button>
        </div>
      )}
      {(inputType === 'email' || inputType === 'default') && (
        <div className='non-password'>
          <input
            type={type}
            className={inputClassName}
            ref={defaultValue}
            onChange={handleChange}
            {...register(name, {
              onChange: handleChange,
              ...(registerOptions || {})
            })}
            name={name}
            maxLength={100}
            {...props}
          />
        </div>
      )}
      {showError && error && <p className='-error'>{error.message}</p>}
      {showError && errors?.map((error, index) => (
        <p className='-error' key={index}>
          {error}
        </p>
      ))}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  error: PropTypes.object,
  optional: PropTypes.bool,
  registerOptions: PropTypes.object,
  showError: PropTypes.bool,
  errors: PropTypes.array,
  onValueChange: PropTypes.func,
  registerCallback: PropTypes.func,
  onChange: PropTypes.func // PropType for onChange
};

InputField.defaultProps = {
  label: '',
  inputType: 'default',
  type: 'text',
  register: () => {},
  error: {},
  optional: false,
  inputRegister: false,
  showError: true,
  errors: [],
  onValueChange: () => {},
  registerCallback: () => {}
};

export default InputField;
