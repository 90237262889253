import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

const ChangePassword = lazy(() => import('./ChangePassword'));
const ChangeEmail = lazy(() => import('./ChangeEmail'));

const routes = [
  {
    path: '/account/password',
    element: (
      <PrivateRoute>
        <ChangePassword />
      </PrivateRoute>
    ),
    title: 'Account - Change Password'
  },
  {
    path: '/account/email',
    element: (
      <PrivateRoute>
        <ChangeEmail />
      </PrivateRoute>
    ),
    title: 'Account - Change Email'
  },
];

export default routes;
