const COLORS = [
  '#143a79',
  '#579fe2',
  '#502b10',
  '#9b3328',
  '#e80b0b',
  '#ec7202',
  '#e5b539',
  '#175f27',
  '#a1b558'
];
export default COLORS;
