import React from 'react';
import PropTypes from 'prop-types';

import HexTile from '@/components/HexTile';

const Avatar = ({ imageUrl = null }) => {
  return (
    <HexTile>
      <HexTile.Image src={imageUrl} />
    </HexTile>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string
};

export default Avatar;
