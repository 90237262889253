import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Controller } from 'react-hook-form';

import Input from 'antd/es/input';

import './styles.scss';

const TextInput = ({ className, control, name, rules, ...props }) => {
  const classNames = clsx('text-input', className);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input className={classNames} size='large' {...field} {...props} />
      )}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default TextInput;
