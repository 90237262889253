import ToastNotification from './ToastNotification';
import Message from './Message';
import Header from './Header';
import Body from './Body';
import Action from './Action';

ToastNotification.Message = Message;
ToastNotification.Header = Header;
ToastNotification.Body = Body;
ToastNotification.Action = Action;

export default ToastNotification;
