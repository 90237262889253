import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ToggleContext } from './Accordion';

const Header = ({ className, children, callback = () => {} }) => {
  const { isActive, setIsActive, disabled } = useContext(ToggleContext);

  const toggle = () => {
    if (disabled) return;
    setIsActive(!isActive);
    callback(!isActive);
  };

  return (
    <div className={clsx('header', className)} onClick={toggle}>
      {children}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
