import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

import { useCreateNote, useUpdateNote } from '@/api/notes';

import {
  TextInput,
  RichTextField,
  ButtonIcon,
  ToastNotification
} from '@/components';

import { NotesListContext } from '@/components/Notes/NotesList';

import './styles.scss';

const DEFAULT_CONTENT_VALUE = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
];

const NoteForm = ({ notableId, notableType }) => {
  const { mutate: createNote, isLoading } = useCreateNote(notableId);
  const { mutate: updateNote } = useUpdateNote(notableId);
  const [noteMessage, setNoteMessage] = useState('');
  const { setActiveForm, currentNote, setCurrentNote } =
    useContext(NotesListContext);

  // check for invalid legacy content type
  const getContent = content => {
    return typeof content === 'object' ? content : false;
  };

  const defaultValues = {
    subject: currentNote?.subject || '',
    content: getContent(currentNote?.content) || DEFAULT_CONTENT_VALUE
  };

  const { control, reset, handleSubmit } = useForm({
    defaultValues
  });

  const { addToast } = useToasts();

  const onSubmit = data => {
    if (isLoading) return;

    const payload = {
      note: {
        notable_id: notableId,
        notable_type: notableType,
        subject: data.subject,
        content: noteMessage
      }
    };

    const mutationCallback = {
      onSuccess: () => {
        reset();
        setActiveForm(false);
        setCurrentNote(null);
      },
      onError: err => {
        const errorMessage = err.response.data.errors[0];
        addToast(
          <ToastNotification
            type='Error'
            variant='error'
            icon='exclamation'
            message={errorMessage}
          />
        );
      }
    };

    if (!currentNote) {
      createNote(payload, mutationCallback);
    } else {
      updateNote({ id: currentNote.id, payload }, mutationCallback);
    }
  };

  const handleClose = () => {
    reset();
    setActiveForm(false);
    setCurrentNote(null);
    setNoteMessage('');
  };

  return (
    <form className='note-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='flex -space-between'>
        <p>{!currentNote ? 'New' : 'Update'} Note</p>
        <div className='flex'>
          <ButtonIcon onClick={handleClose} icon='clear' color='blue-haze' />
          <ButtonIcon type='submit' icon='save' color='deep-sapphire' />
        </div>
      </div>
      <TextInput
        control={control}
        name='subject'
        placeholder='Type Note Subject'
        className='subject-input'
      />
      <RichTextField
        control={control}
        name='content'
        placeholder='Enter note description.'
        onChange={value => setNoteMessage(value)}
      />
    </form>
  );
};

export default NoteForm;
