import * as yup from "yup";

const addPretileValidation = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  middleName: yup.string(),
  suffix: yup.string(),
  company: yup.string(),
  titlePosition: yup.string(),
});

export default addPretileValidation;
