import { useReducer } from 'react';

const modalReducer = (state, { type, payload }) => {
  switch (type) {
    case 'OPEN':
      return { ...state, isOpen: true, ...payload };
    case 'CLOSE':
      return { isOpen: false, children: null };
  }
};

const useModal = () => {
  const [state, dispatch] = useReducer(modalReducer, { isOpen: false });

  const closeModal = () => {
    dispatch({ type: 'CLOSE' });
  };

  const openModal = (Component, modalProps = {}) => {
    dispatch({ type: 'OPEN', payload: { children: Component, ...modalProps } });
  };

  return {
    openModal,
    closeModal,
    modalState: state
  };
};

export default useModal;
