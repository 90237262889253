import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import './styles.scss';
import clsx from 'clsx';

const DropdownData = ({
  label,
  defaultValue,
  dataOptions,
  onChange,
  placeholder,
  flat,
  ...props
}) => {
  const [controlClass, setControlClass] = useState('dropdown-control -empty');
  const defaultOption = defaultValue ?? '';

  const handleChange = event => {
    if (onChange) {
      onChange({ ...event, kind: props.kind });
    }
    setControlClass(clsx('dropdown-control', '-non-empty'));
  };

  const dataValues = flat ? dataOptions.map(o => o.value) : dataOptions;

  return (
    <div className='dropdown-field'>
      {label && <label className='dropdown-label'>{label}</label>}
      <Dropdown
        options={dataValues}
        value={defaultOption}
        onChange={handleChange}
        className='dropdown-x'
        controlClassName={controlClass}
        placeholder={placeholder}
        placeholderClassName='dropdown-placeholder'
        arrowClosed={
          <span className='material-symbols-outlined dropdown-arrow'>
            expand_more
          </span>
        }
        arrowOpen={
          <span className='material-symbols-outlined dropdown-arrow'>
            expand_less
          </span>
        }
        {...props}
      />
    </div>
  );
};

DropdownData.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  dataOptions: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func // PropType for onChange
};

DropdownData.defaultProps = {
  label: '',
  dataOptions: [],
  placeholder: 'Select an option.',
  onChange: () => {}
};

export default DropdownData;
