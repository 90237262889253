import React, { useContext } from 'react';
import clsx from 'clsx';

import { DropdownContext } from './Dropdown';

const Header = ({ children, className, onClick = () => {} }) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  const toggleClassName = isOpen ? '-active' : '-default';

  const handleClick = () => {
    setIsOpen(state => !state);
    onClick();
  };

  return (
    <button
      className={clsx('toggle', className, toggleClassName)}
      type='button'
      onClick={handleClick}
    >
      <div className='icon-container'>
        <span
          className={clsx('material-symbols-outlined icon', toggleClassName)}
        >
          expand_more
        </span>
      </div>
      {children}
    </button>
  );
};

export default Header;
