import React, { useMemo } from 'react';

import { Slate, withReact } from 'slate-react';
import { createEditor } from 'slate';
import Form from './SlateComponents/Form';
import clsx from 'clsx';

const FALLBACK_CONTENT = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
];

const Content = ({ content }) => {
  const editor = useMemo(() => withReact(createEditor()), []);

  const isInvalidContent = typeof content === 'string' || content === null;
  const value = isInvalidContent ? FALLBACK_CONTENT : content;

  // slate does not automatically rerender
  editor.children = value;
  return (
    <div className={clsx('rich-text-field-content', isInvalidContent && 'blurry')}>
      <Slate editor={editor} value={value}>
        <Form readOnly />
      </Slate>
    </div>
  );
};

export default Content;
