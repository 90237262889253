import React, { createContext, useState } from 'react';

import { Accordion, ButtonIcon } from '@/components';
import { useGetExperiences } from '@/api/experience';
import ExperienceForm from './ExperienceForm';
import ExperienceItem from './ExperienceItem';

export const ExperienceContext = createContext();

const ExperienceList = ({ readOnly = true, userId, isActive = false }) => {
  const { data: experiences } = useGetExperiences(userId);
  const [activeForm, setActiveForm] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null);

  const experienceContextValue = {
    activeForm,
    setActiveForm,
    currentExperience,
    setCurrentExperience,
    readOnly
  };

  const renderList = () => {
    if (!experiences) return <></>;

    return experiences.map(experience => (
      <ExperienceItem key={experience.id} experience={experience} />
    ));
  };

  const handleOnClickAdd = () => setActiveForm(true);

  return (
    <ExperienceContext.Provider value={experienceContextValue}>
      {(!!experiences?.length || !readOnly) && (
        <Accordion className='experience-list' active={isActive}>
          <Accordion.ListHeader>Professional History</Accordion.ListHeader>
          <Accordion.Body>
            <Accordion.Body.Actions>
              <ul>
                {!readOnly && (
                  <li>
                    <ButtonIcon
                      type='button'
                      icon='add'
                      color='deep-sapphire'
                      onClick={handleOnClickAdd}
                    />
                  </li>
                )}
              </ul>
            </Accordion.Body.Actions>
            {activeForm && <ExperienceForm />}
            {renderList()}
          </Accordion.Body>
        </Accordion>
      )}
    </ExperienceContext.Provider>
  );
};

export default ExperienceList;
