import {
  AddingContact,
  DynamicFuncContactFields,
  DynamicFuncContactLog,
  DynamicFuncNotes,
  DynamicFuncTags,
  DynamicMenuContactLog,
  DynamicMenuTag,
  IntroContactManagement,
  IntroSynthnode,
  Welcome
} from '.';

const routes = [
  {
    path: '/tutorials/welcome',
    element: <Welcome />,
    title: 'Tutorials - Welcome to Synthnode'
  },
  {
    path: '/tutorials/intro-to-synthnode',
    element: <IntroSynthnode />,
    title: 'Tutorials - Introduction to Synthnode'
  },
  {
    path: '/tutorials/intro-contact-management',
    element: <IntroContactManagement />,
    title: 'Tutorials - Introduction to Contact Management Page'
  },
  {
    path: '/tutorials/adding-contact-manually',
    element: <AddingContact />,
    title: 'Tutorials - Adding a Contact'
  },
  {
    path: '/tutorials/dynamic-function-contact-fields',
    element: <DynamicFuncContactFields />,
    title: 'Tutorials - Dynamic Functionality: Contact Fields'
  },
  {
    path: '/tutorials/dynamic-function-tags',
    element: <DynamicFuncTags />,
    title: 'Tutorials - Dynamic Functionality: Tags'
  },
  {
    path: '/tutorials/dynamic-function-notes',
    element: <DynamicFuncNotes />,
    title: 'Tutorials - Dynamic Functionality: Notes'
  },
  {
    path: '/tutorials/dynamic-function-contact-log',
    element: <DynamicFuncContactLog />,
    title: 'Tutorials - Dynamic Functionality: Contact Log'
  },
  {
    path: '/tutorials/dynamic-menu-tags',
    element: <DynamicMenuTag />,
    title: 'Tutorials - Dynamic Menu: Tag Filtering'
  },
  {
    path: '/tutorials/dynamic-menu-contact-log',
    element: <DynamicMenuContactLog />,
    title: 'Tutorials - Dynamic Menu: Contact Log Filtering'
  }
];

export default routes;
