import React from 'react';

import BusinessCardItem from './BusinessCardItem';

import '../styles/BusinessCardView.scss';

const BusinessCardView = ({ contacts, onClick, onLongPress, tagBorderColor = '' }) => {
  const handleClick = (event, contact)=> {
    event.stopPropagation();

    onClick(contact);
  };

  const handleLongPress = contact => onLongPress(contact);

  return (
    <div className='business-card-list'>
      {contacts.map((contact, index) => {
        return (
          <BusinessCardItem
            onClick={e => handleClick(e, contact)}
            onLongPress={() => handleLongPress(contact)}
            item={contact}
            key={index}
            tagBorderColor={tagBorderColor}
          />
        );
      })}
    </div>
  );
};

export default BusinessCardView;
