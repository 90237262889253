import React, { lazy } from 'react';
import PrivateRoute from "@/privateRoute";

const ExchangeLink = lazy(() => import('.'));

const routes = [
  {
    path: "/exchange-link",
    element: (
      <PrivateRoute>
        <ExchangeLink />
      </PrivateRoute>
    ),
    title: "Exchange Link",
  },
];

export default routes;
