import axiosInstance from '@/lib/helpers/axiosInstance';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const NOTIFICATION_URL = {
  all: `/api/v1/notifications?status=active&order=DESC`,
  connections: `/api/v1/notifications?status=active&notification_type=synth_connection&order=DESC`,
  archived: `/api/v1/notifications?status=archived&order=DESC`
};
const getNotifications = async type => {
  let url = '/api/v1/notifications';
  if (type) url = NOTIFICATION_URL[type];
  const { data } = await axiosInstance().get(url);
  return data.notifications;
};

export const useGetNotifications = (type = 'all') => {
  return useQuery({
    queryKey: ['notifications', type],
    queryFn: () => getNotifications(type)
  });
};

const updateNotifications = async ({ id, payload }) => {
  const response = await axiosInstance().put(
    `/api/v1/notifications/${id}`,
    payload
  );

  return response.data;
};

export const useUpdateNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation(updateNotifications, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] })
  });
};

const clearNotifications = async () => {
  const response = await axiosInstance().post(`/api/v1/notifications/clear`);

  return response.data;
};

export const useClearNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation(clearNotifications, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] })
  });
}
