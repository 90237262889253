import React, { createContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDeleteNote, useGetNotes } from '@/api/notes';

import {
  Accordion,
  Icon,
  NoteForm,
  ButtonIcon,
  RichTextField
} from '@/components';

import './notes-list.scss';

export const NotesListContext = createContext();

const NotesList = ({ notableType, disabled = false }) => {
  const { id: notableId } = useParams();
  const [activeForm, setActiveForm] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);

  const { data } = useGetNotes(notableId, notableType);
  const { mutate: deleteNote } = useDeleteNote(notableId);

  const notesListContextValue = {
    activeForm,
    setActiveForm,
    currentNote,
    setCurrentNote
  };

  const handleEditClick = note => {
    setCurrentNote(note);
    setActiveForm(true);
  };

  const renderList = () => {
    if (!data) return <></>;

    return (
      <div className='notes-list'>
        {data.map((note, index) => {
          const [month, day, year] = note.date.split('/');

          return (
            <div className='note-item' key={`${note.id}-${index}`}>
              <Accordion>
                <Accordion.ListHeader
                  className='note-header'
                  expandIconPosition='end'
                >
                  <div className='value'>
                    <span className='date'>
                      <span>{year}</span>
                      <span>
                        {month}-{day}
                      </span>
                    </span>
                    <span className='subject'>{note.subject}</span>
                  </div>
                </Accordion.ListHeader>
                <Accordion.Body className='note-body'>
                  <RichTextField.Content content={note.content} />
                  <div className='note-buttons'>
                    <Icon name='delete' onClick={() => deleteNote(note.id)} />
                    <Icon name='edit' onClick={() => handleEditClick(note)} />
                  </div>
                </Accordion.Body>
              </Accordion>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <NotesListContext.Provider value={notesListContextValue}>
      <Accordion
        className='notes-accordion list-container-item'
        disabled={disabled}
      >
        <Accordion.ListHeader>Notes</Accordion.ListHeader>
        <Accordion.Body>
          <div className='actions'>
            <ul>
              <li>
                <ButtonIcon
                  type='button'
                  icon='add'
                  color='deep-sapphire'
                  onClick={() => setActiveForm(true)}
                />
              </li>
            </ul>
          </div>
          <div className='content'>
            {activeForm && (
              <NoteForm notableId={notableId} notableType={notableType} />
            )}
            {renderList()}
          </div>
        </Accordion.Body>
      </Accordion>
    </NotesListContext.Provider>
  );
};

export default NotesList;
