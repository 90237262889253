import React from 'react';
import { useLongPress } from 'use-long-press';
import clsx from 'clsx';

import './styles.scss';

const HexTile = ({
  children,
  type,
  isTile = false,
  showTriangleBadge = false,
  onLongPress = () => { },
  borderColor = 'transparent',
  badge = '',
  shadowDisabled = false,
  ...props
}) => {
  const handleLongPress = useLongPress(onLongPress, { threshold: 500 });

  return (
    <div
      className={clsx('hex-tile-wrapper', `-${type}`)}
      {...props}
      {...handleLongPress()}
    >
      {isTile && (
        <>
          <div className='badge'>
            {badge ? (
              <img className='' src={badge} alt='Badge' />
            ) : (
              <div className='outer'>
                <div className='inner'>
                  <div className='content' />
                </div>
              </div>
            )}
          </div>
          {showTriangleBadge && (
            <div className='overlaying-triangle' />
          )}
        </>
      )}
      {!shadowDisabled && <div className='shadow' />}
      <div className='border' style={{ backgroundColor: borderColor }} />
      <div className='hex-tile'>
        <div className='outer'>
          <div className='bg -top'>
            <div className='triangle' />
            <div className='triangle' />
            <div className='triangle' />
          </div>
          <div className='bg -bottom'>
            <div className='triangle' />
            <div className='triangle' />
            <div className='triangle' />
          </div>
          <div className='inner'>
            <div className='content'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HexTile;
