import * as yup from 'yup';

const skillsValidation = yup.object().shape({
  name: yup.string().required('Skill is required'),
  content: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string(),
        children: yup.array(),
        align: yup.string().optional()
      })
    )
    .required('Description is required')
    .test('is-empty', 'Description must not be empty', content => {
      /**
       * *NOTE: Temporary solution.
       *        Must be updated to validate nested bullets
       */
      let emptyNodes = 0;

      content.forEach(node => {
        if (
          node.type === 'paragraph' ||
          node.type === 'heading-one' ||
          node.type === 'heading-two'
        ) {
          if (node.children.length === 1 && node.children[0].text === '') {
            emptyNodes++;
          }
        } else {
          let emptyListNodes = 0;

          node.children.forEach(bullet => {
            if (
              bullet.children.length === 1 &&
              bullet.children[0].text === ''
            ) {
              emptyListNodes++;
            }
          });

          if (emptyListNodes === node.children.length) {
            emptyNodes++;
          }
        }
      });

      return emptyNodes !== content.length;
    })
});

export default skillsValidation;
