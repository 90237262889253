import React from 'react';

import MenuOption from './MenuOption';
import MenuOptionWithChildren from './MenuOptionWithChildren';

import '../styles/MenuGroup.scss';

const MenuGroup = ({
  links,
  title,
  type,
  onClick = () => {},
  onChange = () => {},
  currentOption
}) => {
  return (
    <div className='menu-group'>
      <p className='title'>{title}</p>
      <hr />
      <div className='links'>
        {links.map(link =>
          link?.items ? (
            <MenuOptionWithChildren
              key={link.text}
              {...link}
              onClick={onChange}
              currentOption={currentOption}
            />
          ) : (
            <MenuOption
              key={link.text}
              {...link}
              onClick={onClick}
              type={type}
            />
          )
        )}
      </div>
    </div>
  );
};

export default MenuGroup;
