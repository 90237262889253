import PropTypes from 'prop-types';
import React from 'react';
import { useToggle } from '@/hooks';
import arrowLeftBlue from '@/assets/icons/arrow-left-blue.svg';
import arrowLeftWhite from '@/assets/icons/arrow-left-white.svg';
import arrowRightBlue from '@/assets/icons/arrow-right-blue.svg';
import arrowRightWhite from '@/assets/icons/arrow-right-white.svg';
import './styles.scss';

const SidebarButton = ({
  side,
  className = '',
  onClick = () => {},
  text = null
}) => {
  const [isActive, { toggle }] = useToggle();

  const sidebarButtonClassName = [
    side.toLowerCase(),
    '-blue-haze',
    className,
    isActive ? '-active' : '-inactive'
  ].join(' ');

  const handleClick = () => {
    onClick();
    toggle();
  };

  return side === 'left' ? (
    <button
      className={`sidebar-button ${sidebarButtonClassName}`}
      onClick={handleClick}
    >
      <img src={isActive ? arrowLeftBlue : arrowRightWhite} />
      {text && <p>{text}</p>}
    </button>
  ) : (
    <button
      className={`sidebar-button ${sidebarButtonClassName}`}
      onClick={handleClick}
    >
      <img src={isActive ? arrowRightBlue : arrowLeftWhite} />
      {text && <p>{text}</p>}
    </button>
  );
};

SidebarButton.propTypes = {
  side: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default SidebarButton;
