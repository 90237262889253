import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Message from './Message';
import Header from './Header';
import Body from './Body';
import Action from './Action';

import dateFormatter from '@/lib/helpers/dateFormatter';
import timeFormatter from '@/lib/helpers/timeFormatter';

import check from '@/assets/icons/check.svg';
import alert from '@/assets/icons/lock.svg';
import exclamation from '@/assets/icons/exclamation.svg';

import './styles.scss';

const ToastNotification = ({
  children = <></>,
  dateTime = new Date(),
  icon = 'check',
  message = '',
  type = '',
  variant = 'success'
}) => {
  let iconVariant = '';

  switch (icon) {
    case 'signal':
      iconVariant = alert;
      break;
    case 'exclamation':
      iconVariant = exclamation;
      break;
    default:
      iconVariant = check;
      break;
  }

  const date = dateFormatter(new Date(dateTime));
  const time = timeFormatter(new Date(dateTime));

  const classNames = clsx('icon-container', `-${variant}`);

  return (
    <>
      <div className={classNames}>
        <Icon name={icon} className='icon' />
      </div>
      {type || message ? (
        <>
          <Message>
            <Header>{type}</Header>
            <Body>
              <span className='secondary'>{message}</span>
              <span className='date-time'>{date}</span>
              <span className='date-time'>{time}</span>
            </Body>
          </Message>
          <Action />
        </>
      ) : (
        children
      )}
    </>
  );
};

ToastNotification.propTypes = {
  children: PropTypes.node,
  dateTime: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    'success',
    'primary-info',
    'secondary-info',
    'warning',
    'error'
  ])
};

export default ToastNotification;
