import React from 'react';

import './styles.scss';

const Loader = ({ src, alt = 'Loading ...' }) => (
  <div className='loader'>
    <img src={src} alt={alt} />
  </div>
);

export default Loader;
