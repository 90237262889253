import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Message = ({ children = <></>, className = '' }) => {
  return <div className={clsx('message', className)}>{children}</div>;
};

Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Message;
