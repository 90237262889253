const KIND_TYPE = {
  address: 'place',
  call: 'call',
  email: 'mail',
  website: 'language',
  sms: 'sms',
  place: 'place', // TEMPORARY: will be removed after rake task
  mail: 'mail', // TEMPORARY: will be removed after rake task
  language: 'language',
};

export default KIND_TYPE;
