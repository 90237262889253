import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Header = ({ children = <></>, className = '' }) => {
  return <div className={clsx('header', className)}>{children}</div>;
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Header;
