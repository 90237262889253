import React, { useMemo } from 'react';

import HexTile from '../HexTile';

import './styles.scss';

const Preview = ({ label, src, type = 'avatar_url' }) => {
  const component = useMemo(() => {
    switch (type) {
      case 'avatar_url':
        return (
          <div className='tile-container'>
            <HexTile>
              <HexTile.Image src={src} />
            </HexTile>
          </div>
        );
      case 'card_photo_url':
        return <img src={src} className='business-card' />;
      default:
        return (
          <div className='logo'>
            <img src={src} alt='Logo' />
          </div>
        );
    }
  }, [type, src]);

  return (
    <div className='cropper-preview'>
      <label>{label}</label>
      {component}
    </div>
  );
};

export default Preview;
