import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-unresolved
import App from '@/App';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from '@/providers/AppProvider';

import '@fontsource/inter/variable.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <AppProvider>
        <App />
      </AppProvider>
    </Router>
  </React.StrictMode>
);

if (module.hot) {
  module.hot.accept('./src/App.js', () => {
    root.render(
      <React.StrictMode>
        <Router>
          <AppProvider>
            <App />
          </AppProvider>
        </Router>
      </React.StrictMode>
    );
  });
}
