import React from 'react';
import clsx from 'clsx';

import { Accordion } from '@/components';

import '../styles/MenuOption.scss';

const MenuOptionWithChildren = ({
  icon,
  text = '',
  items = [],
  onClick = () => {},
  currentOption
}) => {
  return (
    <div className='menu-option -list'>
      <div className='icon-container'>
        <img className='icon' src={icon} />
      </div>
      <div className='items'>
        <Accordion>
          <Accordion.ListHeader>{text}</Accordion.ListHeader>
          <Accordion.Body>
            <ul>
              {items.map(item => (
                <li key={item.value} onClick={() => onClick(item.value)}>
                  <span>{item.text}</span>
                  <span
                    className={clsx(
                      'radio',
                      currentOption === item.value && '-active'
                    )}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion>
      </div>
    </div>
  );
};

export default MenuOptionWithChildren;
