import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateSkill, useUpdateSkill } from '@/api/skill';

import { SkillContext } from './SkillList';
import {
  TextInput,
  RichTextField,
  ButtonIcon,
  ToastNotification
} from '@/components';

import { skillsValidation } from '@/validators';

import '../styles/SkillForm.scss';

const SkillForm = () => {
  const { mutate: createSkill, isLoading } = useCreateSkill();
  const { mutate: updateSkill } = useUpdateSkill();
  const { addToast } = useToasts();
  const { currentSkill, setCurrentSkill, setActiveForm } =
    useContext(SkillContext);
  const [skill, _setSkill] = useState(currentSkill);
  const [skillMessage, setSkillMessage] = useState('');
  const defaultValues = {
    name: skill?.name || '',
    content: skill?.content || ''
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues
  });

  const onSubmit = data => {
    if (isLoading) return;

    const payload = {
      skill: {
        name: data.name,
        content: skillMessage
      }
    };
    const requestOptions = {
      onSuccess: () => {
        reset();
        setActiveForm(false);
        setCurrentSkill(null);
      },
      onError: err => {
        const errorMessage = err.response.data.errors[0];
        addToast(
          <ToastNotification
            type='Error'
            variant='error'
            icon='exclamation'
            message={`Skill ${errorMessage}`}
          />
        );
      }
    };

    if (!skill) {
      createSkill(payload, requestOptions);
    } else {
      updateSkill({ id: skill.id, payload }, requestOptions);
    }
  };

  const onCancel = () => {
    reset();
    setCurrentSkill(null);
    setActiveForm(false);
    setSkillMessage('');
  };

  return (
    <form className='skill-form' onSubmit={handleSubmit(onSubmit)}>
      <p>Skill</p>
      <TextInput
        control={control}
        name='name'
        placeholder='"Software / Project Management/ Etc"'
      />
      <p>Description</p>
      <RichTextField
        control={control}
        name='content'
        placeholder='Description is required'
        onChange={value => setSkillMessage(value)}
      />
      <div className='actions flex -end'>
        <ButtonIcon icon='clear' onClick={onCancel} color='blue-haze' />
        <ButtonIcon icon='save' type='submit' color='deep-sapphire' />
      </div>
    </form>
  );
};

export default SkillForm;
