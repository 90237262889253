import React, { lazy } from 'react';
import PublicRoute from '@/publicRoute';

const PasswordRecovery = lazy(() => import('.'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword  = lazy(() => import('./components/ResetPassword'));

const routes = [
  {
    path: '/password-recovery',
    element: (
      <PublicRoute>
        <PasswordRecovery/>
      </PublicRoute>
    ),
    title: 'Forgot Password',
    children: [
      {
        index: true,
        element: (
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        )
      },
      {
        path: ':token',
        element: (
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        )
      }
    ]
  }
];

export default routes;
