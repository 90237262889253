import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

const Profile = lazy(() => import('.'));

const routes = [
  {
    path: '/profile',
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
    title: 'Profile'
  }
];

export default routes;
