import React, { useContext } from 'react';
import { useDeleteSkill } from '@/api/skill';

import { Accordion, Icon, RichTextField, ButtonIcon } from '@/components';

import { SkillContext } from './SkillList';

import '../styles/SkillItem.scss';

const SkillItem = ({ skill, withHandle = false }) => {
  const { mutate: deleteSkill } = useDeleteSkill();
  const { setActiveForm, setCurrentSkill, readOnly } = useContext(SkillContext);

  const onDelete = e => {
    e.stopPropagation();
    deleteSkill(skill.id);
  };

  const onEdit = e => {
    e.stopPropagation();
    setActiveForm(true);
    setCurrentSkill(skill);
  };

  return (
    <Accordion className='skill-item'>
      <Accordion.ListHeader>
        <div className='flex'>
          {withHandle && (
            <div className='icontainer drag-handle'>
              <span className='material-symbols-outlined more'>more_vert</span>
            </div>
          )}
          <div className='skill-item-stat'>
            <div className='circle' />
          </div>
          <div className='skill-item-header'>{skill.name}</div>
        </div>
      </Accordion.ListHeader>
      <Accordion.Body>
        {!readOnly && (
          <Accordion.Body.Actions>
            <ul>
              <li>
                <ButtonIcon
                  type='button'
                  icon='delete'
                  color='deep-sapphire'
                  onClick={onDelete}
                />
              </li>
              <li>
                <ButtonIcon
                  icon='edit_square'
                  color='deep-sapphire'
                  onClick={onEdit}
                />
              </li>
            </ul>
          </Accordion.Body.Actions>
        )}
        <RichTextField.Content content={skill.content} />
      </Accordion.Body>
    </Accordion>
  );
};

export default SkillItem;
