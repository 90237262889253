import React from 'react';
import clsx from 'clsx';

import './styles.scss';

const StatusCircle = ({ className, color }) => {
  const classNames = clsx('status-circle', className);
  return (
    <div className={classNames}>
      <div className='circle' style={{ backgroundColor: color }} />
    </div>
  );
};

export default StatusCircle;
