import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const req = require.context('./pages', true, /route\.js$/);
let routes = [];

req.keys().forEach(key => {
  routes = routes.concat(req(key).default);
});

const Router = () => {
  const elements = useRoutes(
    routes.map(({ element, title, ...route }) => {
      return {
        ...route,
        element: (
          <Suspense fallback={<> loading </>}>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {element}
          </Suspense>
        )
      };
    })
  );
  return elements;
};

export default Router;
