import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Controller } from 'react-hook-form';

import Input from 'antd/es/input';

import './styles.scss';

const TextArea = ({ className, control, name, ...props }) => {
  const classNames = clsx('textarea', className);

  return (
    <Controller
      name={name}
      control={control}
      render={
        ({ field }) => { 
          return (
            <Input.TextArea
              className={classNames}
              maxLength={1000}
              {...field}
              {...props}
            />
          )
        }
      }
    />

  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default TextArea;
