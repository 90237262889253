import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

const EditTags = lazy(() => import('.'));

const routes = [
  {
    path: '/tags/edit',
    element: (
      <PrivateRoute>
        <EditTags />
      </PrivateRoute>
    ),
    title: 'Edit Tags'
  }
];

export default routes;
