import PropTypes from "prop-types";
import React from "react";
import "./styles.scss";

const ToastNotificationContainer = ({ children }) => {
  return <div className="toast-notification">{children}</div>;
};

ToastNotificationContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default ToastNotificationContainer;
