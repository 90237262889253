import * as yup from 'yup';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const signupV2Validation = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email Address is required')
    .lowercase(),
  password: yup
    .string()
    .matches(/(?=.{8})/, 'Password must be at least 8 characters long')
    .matches(
      /(?=^.{0,255}$)/,
      'Password must be no more than 255 characters long'
    )
    .matches(/(?=.*[a-z])/, 'Password must have at least 1 lower-cased letter')
    .matches(/(?=.*[A-Z])/, 'Password must have at least 1 capital letter')
    .matches(/(?=.*[0-9])/, 'Password must have at least 1 number')
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      'Contains at least one special symbol (e.g., !, @, #, $, %)'
    )
    .required('Password is empty'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  nameFirst: yup
    .string()
    .required('Name: First is required'),
  nameLast: yup
    .string()
    .required('Name: Last is required'),
  company: yup
    .string()
    .required('Company is required'),
  companyTitle: yup
    .string()
    .required('Title is required'),
  addressOne: yup
    .string()
    .required('Address 1: Office is required'),
  emailProfOne: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email 1: Professional Primary is required'),
  telephoneMobile: yup
    .string()
    // .matches(phoneRegExp, 'Please enter a valid mobile/sms number.')
    .required('Telephone: Mobile/SMS is required'),
  companyWebsite: yup
    .string()
    .required('Company Website is required')
});

export default signupV2Validation;
