import React from 'react';
import PropTypes from 'prop-types';
import RNModal from 'react-modal';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';

import './styles.scss';

const Header = ({ className = '', children }) => (
  <div className={clsx('header', className)}>{children}</div>
);

const Body = ({ children, className }) => (
  <div className={clsx('body', className)}>{children}</div>
);

const Modal = ({
  children,
  isOpen = false,
  closeModal = () => {},
  className = 'partial'
}) => {
  const contentClassName = clsx('modal-content', className);

  return (
    <RNModal
      overlayClassName={clsx('modal-overlay', isMobile ? '-mobile' : '')}
      className={contentClassName}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      {children}
    </RNModal>
  );
};

Modal.Header = Header;
Modal.Body = Body;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['full', 'partial'])
};

export default Modal;
