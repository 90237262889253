import React, { useEffect, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import { decamelizeKeys } from 'humps';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { isBrowser, isMobile } from 'react-device-detect';
import _ from 'lodash';

import {
  useGetUser,
  useUpdateProfile,
  useUploadProfileCard,
  useUploadProfilePhoto
} from '@/api/users';

import {
  Accordion,
  Button,
  ButtonIcon,
  HexTile,
  InputField,
  ToastNotification,
  UploadButtons,
  VirtualCard
} from '@/components';

import ExperienceList from '../../components/ExperienceList';
import SkillList from '../../components/SkillList';
import BusinessLogoUpload from '../../components/BusinessLogoUpload';

import getFullName from '@/lib/helpers/getFullName';
import { userEditValidation } from '@/validators';

import pretileLoader from '@/assets/images/pretile-loader.gif';

import './styles.scss';
import DropdownData from '@/components/DropdownData';
import { useGetDropdownOptions } from '@/api/dropdownOptions';
import UserNote from '../../components/UserNote';

const DEFAULT_CONTENT_VALUE = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
];

const EditProfile = () => {
  const userId = Cookies.get('userId');
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { data } = useGetUser();
  const userInfo = data?.user?.user_info;
  const { mutate: updateProfile } = useUpdateProfile();
  const { mutate: uploadProfilePhoto, isLoading: isUploadingProfilePhoto } =
    useUploadProfilePhoto();
  const { mutate: uploadProfileCard, isLoading: isUploadingCardPhoto } =
    useUploadProfileCard();

  const [timezoneData, setTimezoneData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [noteMessage, setNoteMessage] = useState([]);
  const [noteBool, setNoteBool] = useState(false);

  useEffect(() => {
    const fetchDropdowns = async () => {
      const indData = await useGetDropdownOptions('company_industry');
      const tzData = await useGetDropdownOptions('timezone');
      setTimezoneData(tzData);
      setIndustryData(indData);
    };
    fetchDropdowns();
  }, []);

  const [avatarPreview, setAvatarPreview] = useState(null);
  const [cardPreview, setCardPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    company: '',
    title_position: ''
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty },
    setValue,
    getValues
  } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(userEditValidation)
  });

  const fieldFormRef = useRef(null);
  const containerRef = useRef(null);

  const handleErrorMessage = error => {
    if (error.response.data) {
      setErrorMessage('incorrect file');
    } else {
      setErrorMessage('There was an error to your request.');
    }
  };

  const handleUploadAvatarChange = ({ file }) => {
    if (file.status === 'done') {
      const imageObj = file.originFileObj;
      const imageUrl = URL.createObjectURL(imageObj);

      setAvatarPreview(imageUrl);

      onAvatarSubmit(imageObj);
    }
  };

  const handleUploadCardPhotoChange = ({ file }) => {
    if (file.status === 'done') {
      const imageObj = file.originFileObj;
      const imageUrl = URL.createObjectURL(imageObj);

      setCardPreview(imageUrl);

      onCardSubmit(imageObj);
    }
  };

  const handleCropOpen = type => {
    const link = isBrowser
      ? '/contact-management/profile/edit/crop'
      : '/profile/edit/crop';

    navigate(link, {
      state: {
        image: type
      }
    });
  };

  const onAvatarSubmit = imageObj => {
    const payload = new FormData();

    if (imageObj) payload.append('avatar', imageObj);

    uploadProfilePhoto(
      { id: userId, payload },
      {
        onSuccess: () => {
          setAvatarPreview(null);
          addToast(
            <ToastNotification
              type='Success'
              message='Successfully uploaded the avatar!'
            />
          );
        },
        onError: error => {
          handleErrorMessage(error);
          console.error('error', error);
        }
      }
    );
  };

  const onCardSubmit = imageObj => {
    const payload = new FormData();

    if (imageObj) payload.append('card_photo', imageObj);

    uploadProfileCard(
      { id: userId, payload },
      {
        onSuccess: () => {
          setCardPreview(null);
          addToast(
            <ToastNotification
              type='Success'
              message='Successfully uploaded the business card photo!'
            />
          );
        },
        onError: error => {
          handleErrorCardMessage(error);
          console.error('error', error);
        }
      }
    );
  };

  const onUpdateProfile = (data, showToast = true) => {
    const payload = decamelizeKeys({
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        suffix: data.suffix,
        company: data.company,
        title_position: data.title_position,
        user_info_attributes: {
          company_industry: data.company_industry,
          timezone: data.timezone,
          user_note: noteMessage
        }
      }
    });
    setNoteBool(false);
    updateProfile(
      { id: userId, payload },
      {
        onSuccess: () => {
          if (showToast) {
            addToast(
              <ToastNotification
                type='Success'
                message='Your profile has been successfully updated!'
              />
            );
          }
        },
        onError: error => {
          if (showToast) {
            addToast(
              <ToastNotification
                type='Error'
                message='Your profile has not been updated!'
              />
            );
          }
          console.error('error', error);
        }
      }
    );
  };

  // Virtual Business Card Upload
  const handleErrorCardMessage = error => {
    if (error.response.data) {
      setErrorMessage('incorrect file');
    } else {
      setErrorMessage('There was an error to your request.');
    }
  };

  const EditProfileButton = () => {
    document.getElementById('edit-profile-button').click();
  };

  const handleNavigateBack = () => {
    navigate('/profile');
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      const values = {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        middleName: getValues('middleName'),
        suffix: getValues('suffix'),
        company: getValues('company'),
        title_position: getValues('title_position')
      };

      if (
        isDirty &&
        event.target &&
        !fieldFormRef.current.contains(event.target) &&
        containerRef.current.contains(event.target)
      ) {
        handleSubmit(onUpdateProfile(values));
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [fieldFormRef, containerRef]);

  useEffect(() => {
    if (data?.user) {
      const {
        first_name,
        last_name,
        middle_name,
        suffix,
        company,
        title_position
      } = data.user;
      setValue('firstName', first_name || '');
      setValue('lastName', last_name || '');
      setValue('middleName', middle_name || '');
      setValue('suffix', suffix || '');
      setValue('company', company || '');
      setValue('title_position', title_position || '');
      if (userInfo) {
        setNoteMessage(
          !userInfo.user_note || userInfo.user_note.length == 0
            ? DEFAULT_CONTENT_VALUE
            : userInfo.user_note
        );
      }
    }
  }, [data]);

  const onUpdateProfileNoToast = data => {
    onUpdateProfile(data, false);
  };

  // Create a debounced version of onUpdateProfile
  const debouncedUpdateProfile = useCallback(
    _.debounce(onUpdateProfileNoToast, 1000),
    []
  );

  // Listen for changes in form values
  const handleFormChange = () => {
    // Call the debounced version of onUpdateProfile
    // debouncedUpdateProfile(getValues());
  };

  const handleDropdown = e => {
    setValue(e.kind, e.value);
  };

  const handleNoteClick = (e, name) => {
    setNoteBool(!noteBool);
    if (name) setValue(name, noteMessage.length == 0 ? DEFAULT_CONTENT_VALUE : noteMessage);
  };

  const handleNoteChange = value => {
    setNoteMessage(value);
  };

  return (
    <div
      className={clsx(
        'profile-edit-page',
        isMobile && '-mobile',
        isKeyboardOpen && '-keyboard-open'
      )}
      ref={containerRef}
    >
      <div className='top'>
        <div className='header'>
          {isBrowser ? (
            <div className='spacer' />
          ) : (
            <ButtonIcon
              icon='navigate_before'
              color='white'
              onClick={handleNavigateBack}
            />
          )}
          <div className='title'>Upload Contact Photo</div>
          <div className='spacer' />
        </div>
        <div className='tile-container'>
          {isUploadingProfilePhoto ? (
            <HexTile isTile badge={data?.user.business_logo_url}>
              <HexTile.Image src={pretileLoader} />
            </HexTile>
          ) : (
            <HexTile isTile badge={data?.user.business_logo_url}>
              <HexTile.Image src={avatarPreview || data?.user.avatar_url} />
            </HexTile>
          )}
        </div>
        {errorMessage && (
          <p style={{ color: 'red', fontSize: 15 }}>{errorMessage}</p>
        )}
        <UploadButtons
          onUploadChange={handleUploadAvatarChange}
          onCropOpen={() => handleCropOpen('avatar_url')}
          isUploading={isUploadingProfilePhoto}
          hasUploadValue={data?.user.avatar_url}
        />
      </div>
      <form
        onSubmit={handleSubmit(onUpdateProfile)}
        className='profile-edit-inputs'
        ref={fieldFormRef}
      >
        <div className='profile-name'>
          <span>{getFullName(data?.user) || 'Synthnode User'}</span>
        </div>
        <InputField
          label='Name: First'
          inputType='default'
          placeholder='First Name'
          name='firstName'
          register={register}
          error={errors.firstName}
          onChange={handleFormChange}
        />
        <InputField
          label='Name: Last'
          inputType='default'
          placeholder='Last Name'
          name='lastName'
          register={register}
          error={errors.lastName}
          onChange={handleFormChange}
        />
        <InputField
          label='Name: Middle'
          inputType='default'
          placeholder='Optional'
          name='middleName'
          register={register}
          error={errors.middleName}
          onChange={handleFormChange}
          optional
        />
        <InputField
          label='Name: Suffix'
          inputType='default'
          placeholder='Optional'
          name='suffix'
          register={register}
          error={errors.suffix}
          onChange={handleFormChange}
          optional
        />
        <InputField
          label='Company'
          inputType='default'
          placeholder='Company'
          name='company'
          register={register}
          error={errors.company}
          onChange={handleFormChange}
        />
        <DropdownData
          dataOptions={industryData}
          defaultValue={userInfo?.company_industry || ''}
          label='Company Industry'
          onChange={handleDropdown}
          kind='company_industry'
          flat={true}
        />
        <InputField
          label='Title Position'
          inputType='default'
          placeholder='Title Position'
          name='title_position'
          register={register}
          error={errors.title_position}
          onChange={handleFormChange}
        />
        <DropdownData
          dataOptions={timezoneData}
          defaultValue={userInfo?.timezone || ''}
          label='Timezone'
          onChange={handleDropdown}
          kind='timezone'
          flat={true}
        />
        <button id='edit-profile-button' style={{ display: 'none' }} />
      </form>
      <div className='accordions'>
        <Accordion className='business-card-photo'>
          <Accordion.ListHeader>
            Upload Business card photo
          </Accordion.ListHeader>
          <Accordion.Body>
            <div className='business-card-section'>
              <VirtualCard
                imageUrl={cardPreview || data?.user.card_photo_url}
              />
              {errorMessage && (
                <p style={{ color: 'red', fontSize: 15 }}>{errorMessage}</p>
              )}
              <UploadButtons
                onUploadChange={handleUploadCardPhotoChange}
                onCropOpen={() => handleCropOpen('card_photo_url')}
                isUploading={isUploadingCardPhoto}
                hasUploadValue={data?.user.card_photo_url}
              />
            </div>
          </Accordion.Body>
        </Accordion>
        <BusinessLogoUpload
          userId={userId}
          logo={data?.user.business_logo_url}
        />
        <ExperienceList userId={userId} readOnly={false} />
        <SkillList userId={userId} readOnly={false} />
      </div>
      <UserNote
        noteMessage={noteMessage}
        noteBool={noteBool}
        onClick={handleNoteClick}
        onChange={handleNoteChange}
        label='Note'
        control={control}
        name='user_note'
      />
      <div className='button-container'>
        <Button block size='large' onClick={EditProfileButton}>
          Save Profile
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;
