import React from 'react';

import Icon from '../Icon';
import UploadFile from '../UploadFile';
import Button from '../Button';

import './styles.scss';

const UploadButtons = ({
  onUploadChange,
  onCropOpen,
  isUploading,
  hasUploadValue
}) => {
  return (
    <ul className='upload-btn-container'>
      <li>
        <UploadFile
          onChange={onUploadChange}
          accept='image/*'
          capture='environment'
        >
          <Button
            className='w-100'
            icon={<Icon className='icon -small' name='photo_camera' />}
            size='large'
            disabled={isUploading}
          >
            Take
          </Button>
        </UploadFile>
      </li>
      <li>
        <UploadFile onChange={onUploadChange} accept='.png, .jpg, .jpeg'>
          <Button
            className='w-100'
            icon={<Icon className='icon -small' name='add_a_photo' />}
            size='large'
            disabled={isUploading}
          >
            Upload
          </Button>
        </UploadFile>
      </li>
      <li>
        {hasUploadValue && (
          <Button
            icon={<Icon name='crop' className='icon -small' />}
            size='large'
            className='w-100'
            onClick={onCropOpen}
            disabled={isUploading}
          >
            CROP
          </Button>
        )}
      </li>
    </ul>
  );
};

export default UploadButtons;
