import React, { createContext, forwardRef, useImperativeHandle, useState  } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.scss';

export const ToggleContext = createContext();

const Accordion = forwardRef(({
  className = '',
  children,
  disabled = false,
  active = false
}, ref) => {
  const [isActive, setIsActive] = useState(active);

  useImperativeHandle(ref, () => {
    return {
      toggle: () => setIsActive(!isActive)
    };
  });

  const classNames = clsx('accordion', isActive && '-active', className);

  return (
    <ToggleContext.Provider value={{ isActive, setIsActive, disabled }}>
      <div className={classNames} disabled={disabled}>
        {children}
      </div>
    </ToggleContext.Provider>
  );
});

Accordion.propTypes = {
  className: PropTypes.string
};

export default Accordion;
