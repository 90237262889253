import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import addTile from '../../assets/icons/add-tile.svg';

import { Checkbox } from 'antd';

import './styles.scss';
import HexTile from '../HexTile';

const ListAvatar = ({
  avatarUrl = null,
  name = '',
  size = '0.3',
  onClick = () => { },
  company = 'Company',
  title = 'title',
  variant = 'pretile',
  isSelectable = false,
  onChangeCheckbox = () => {},
  checkBoxValue = false
}) => {
  return (
    <div
      className={clsx('list-avatar', variant === 'preview' && '-preview')}
      onClick={onClick}
    >
      {isSelectable && <Checkbox onChange={onChangeCheckbox} checked={checkBoxValue}/>}
      <div className='tile-container'>
        <HexTile>
          <HexTile.Image src={avatarUrl} />
        </HexTile>
      </div>
      <div className='info'>
        <p>{name}</p>
        <span>{`${title}, ${company}`}</span>
      </div>
      {variant === 'pretile' && <div className='rect' />}
      {variant === 'search' && (
        <button className='add-btn'>
          <img src={addTile} />
        </button>
      )}
    </div>
  );
};

ListAvatar.propTypes = {
  avatarUrl: PropTypes.oneOf([PropTypes.string, null]),
  company: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
};

export default ListAvatar;
