import PrivateRoute from "@/privateRoute";
import CropAvatar from ".";

const routes = [
  {
    path: "/profile/edit/crop",
    element: (
      <PrivateRoute>
        <CropAvatar />
      </PrivateRoute>
    ),
    title: "Crop Avatar",
  },
];

export default routes;
