import React from 'react';

import { HexTile } from '@/components';

import getFullName from '@/lib/helpers/getFullName';

import editPretile from '@/assets/icons/edit-pretile.svg';

import './ProfileBlock.scss';

const ProfileBlock = ({
  data,
  handleLongPress = () => { },
  isEdit = false,
  onEdit = () => { },
  children
}) => {
  return (
    <div className='info-block'>
      <div className='tile-container' {...handleLongPress()}>
        <div className='spacer' />
        <HexTile
          isTile={data?.business_logo_url || data?.business_logo_url === null}
          badge={data?.business_logo_url}
        >
          <HexTile.Image src={data.avatar_url} />
        </HexTile>
        <div className='spacer'>
          {isEdit && (
            <img src={editPretile} className='edit' onClick={onEdit} />
          )}
        </div>
      </div>
      {children ? (
        { ...children }
      ) : (
        <>
          <center className='name'>{getFullName(data)}</center>
          {(data?.title_position || data?.company) && (
            <div className='profession'>
              {data?.title_position && (
                <p className='position'>{data?.title_position}</p>
              )}

              {data?.title_position && (
                <p className='company'>{data?.company}</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileBlock;
