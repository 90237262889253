import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '@/lib/helpers/axiosInstance';
import { orderBy } from 'lodash';

const getSkills = async userId => {
  const url = `/api/v1/skills?user_id=${userId}`;
  const { data } = await axiosInstance().get(url);

  return orderBy(data.skills, ['position', 'desc']) || [];
};

export const useGetSkills = userId => {
  return useQuery({
    queryKey: ['skills'],
    queryFn: () => getSkills(userId)
  });
};

const createSkill = async payload => {
  const response = await axiosInstance().post('/api/v1/skills', payload);

  return response.data;
};

export const useCreateSkill = () => {
  const queryClient = useQueryClient();

  return useMutation(createSkill, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['skills'] })
  });
};

const updateSkill = async ({ id, payload }) => {
  const response = await axiosInstance().put(`/api/v1/skills/${id}`, payload);

  return response.data;
};

export const useUpdateSkill = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSkill, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['skills'] })
  });
};

const deleteSkill = async id => {
  const response = await axiosInstance().delete(`/api/v1/skills/${id}`);

  return response.data;
};

export const useDeleteSkill = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSkill, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['skills'] })
  });
};

const updateSkillsPositions = async ({ payload }) => {
  const response = await axiosInstance().put(
    `/api/v1/skills/position`,
    payload
  );

  return response.data;
};

export const useUpdateSkillsPositions = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSkillsPositions, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['skills'] })
  });
};
