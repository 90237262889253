import React, { lazy } from 'react';
import PrivateRoute from '@/privateRoute';

import { isMobile } from 'react-device-detect';

const Tags = lazy(() => import('@/pages/Tags'));
const Profile = lazy(() => import('@/pages/Profile'));
const TriangleIntroduction = lazy(() => import('@/pages/TriangleIntroduction'));
const IntroductionRespond = lazy(() =>
  import('@/pages/TriangleIntroduction/IntroductionRespond')
);
const IntroductionReview = lazy(() =>
  import('@/pages/TriangleIntroduction/IntroductionReview')
);
const PretileAddForm = lazy(() =>
  import('@/pages/Profile/components/PretileAddForm')
);
const UploadVCF = lazy(() => import('@/pages/UploadVCF'));
const PhotoUpload = lazy(() => import('../PhotoUpload'));
const ProcessContacts = lazy(() => import('@/pages/ProcessContacts'));
const ExchangeLink = lazy(() => import('../ExchangeLink'));
const UserProfile = lazy(() => import('../User/Profile'));
const EditUserProfile = lazy(() => import('../User/Profile/EditProfile'));
const EditUserProfileCrop = lazy(() => import('../User/Profile/CropAvatar'));

const Connection = lazy(() => import('@/pages/Connection'));
const Request = lazy(() => import('@/pages/Connection/Request'));
const Outgoing = lazy(() => import('@/pages/Connection/Outgoing'));
const Incoming = lazy(() => import('@/pages/Connection/Incoming'));

const EditTags = lazy(() => import('@/pages/EditTags'));
const PreTags = lazy(() => import('@/pages/PreTags'));

const GenericExchangeLink = lazy(() => import('@/pages/GenericExchangeLink'));
const GenericExchangeLinksManagement = lazy(() => import('@/pages/GenericExchangeLinksManagement'));
const ExchangeLinkQR = lazy(() => import('@/pages/ExchangeLinkQR'));

const ContactManagementPage = lazy(() => import('./ContactManagement'));

let routes = [
  {
    path: '/contact-management/*',
    element: (
      <PrivateRoute>
        <ContactManagementPage />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'pretiles/:id',
        element: (
          <PrivateRoute>
            <Profile type='pretile' />
          </PrivateRoute>
        )
      },
      {
        path: 'pretiles/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='Pretile' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Pretile Tags'
      },
      {
        path: 'upload-vcf',
        element: (
          <PrivateRoute>
            <UploadVCF />
          </PrivateRoute>
        )
      },
      {
        path: 'pretiles/add',
        element: (
          <PrivateRoute>
            <PretileAddForm />
          </PrivateRoute>
        )
      },
      {
        path: 'pretiles/:id/photo-upload',
        element: (
          <PrivateRoute>
            <PhotoUpload />
          </PrivateRoute>
        )
      },
      {
        path: 'process-contacts',
        element: (
          <PrivateRoute>
            <ProcessContacts />
          </PrivateRoute>
        )
      },
      {
        path: 'tiles/:id',
        element: (
          <PrivateRoute>
            <Profile type='tile' />
          </PrivateRoute>
        )
      },
      {
        path: 'tiles/:id/triangle-introduction',
        element: (
          <PrivateRoute>
            <TriangleIntroduction />
          </PrivateRoute>
        )
      },
      {
        path: 'tiles/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='SynthConnection' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Tile Tags'
      },
      {
        path: 'connections',
        element: (
          <PrivateRoute>
            <Connection />
          </PrivateRoute>
        )
      },
      {
        path: 'connections/new',
        element: (
          <PrivateRoute>
            <Request />
          </PrivateRoute>
        )
      },
      {
        path: 'connections/:id/outgoing',
        element: (
          <PrivateRoute>
            <Outgoing />
          </PrivateRoute>
        )
      },
      {
        path: 'connections/:id/incoming',
        element: (
          <PrivateRoute>
            <Incoming />
          </PrivateRoute>
        )
      },
      {
        path: 'introductions/:id',
        element: (
          <PrivateRoute>
            <IntroductionRespond />
          </PrivateRoute>
        )
      },
      {
        path: 'introductions/:id/review',
        element: (
          <PrivateRoute>
            <IntroductionReview />
          </PrivateRoute>
        )
      },
      {
        path: 'exchange-link',
        element: (
          <PrivateRoute>
            <ExchangeLink />
          </PrivateRoute>
        )
      },
      {
        path: 'profile',
        element: (
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        )
      },
      {
        path: 'profile/edit',
        element: (
          <PrivateRoute>
            <EditUserProfile />
          </PrivateRoute>
        )
      },
      {
        path: 'profile/edit/crop',
        element: (
          <PrivateRoute>
            <EditUserProfileCrop />
          </PrivateRoute>
        )
      },
      {
        path: 'tags/edit',
        element: (
          <PrivateRoute>
            <EditTags />
          </PrivateRoute>
        )
      },
      {
        path: 'pre-tags',
        element: (
          <PrivateRoute>
            <PreTags />
          </PrivateRoute>
        ),
        title: 'Manage Pre-tags'
      },
      {
        path: 'pre-tags/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='PreTag' />
          </PrivateRoute>
        ),
        title: 'Manage Pre-tags Taglist'
      },
      {
        path: 'generic-exchange-link',
        element: (
          <PrivateRoute>
            <GenericExchangeLink />
          </PrivateRoute>
        ),
        title: 'Create Generic Exchange Link'
      },
      {
        path: 'generic-exchange-link/:token',
        element: (
          <PrivateRoute>
            <ExchangeLinkQR />
          </PrivateRoute>
        ),
        title: 'Created Generic Exchange Link'
      },
      {
        path: 'generic-exchange-links',
        element: (
          <PrivateRoute>
            <GenericExchangeLinksManagement />
          </PrivateRoute>
        ),
        title: 'Manage Generic QR Links'
      },
    ],
    title: 'Contact Management'
  }
];

// Add this route if mobile
if (isMobile) {
  routes.push(
    ...[
      {
        path: '/contact-management/pretiles/:id',
        element: (
          <PrivateRoute>
            <Profile type='pretile' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Pretile'
      },
      {
        path: '/contact-management/pretiles/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='Pretile' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Pretile Tags'
      },
      {
        path: '/contact-management/tiles/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='SynthConnection' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Tile Tags'
      },
      {
        path: '/contact-management/pretiles/:id/photo-upload',
        element: (
          <PrivateRoute>
            <PhotoUpload />
          </PrivateRoute>
        ),
        title: 'Contact Management - Pretile Photo Upload'
      },
      {
        path: '/contact-management/pretiles/add',
        element: (
          <PrivateRoute>
            <Profile>
              <PretileAddForm />
            </Profile>
          </PrivateRoute>
        ),
        title: 'Contact Management - Add Pretile'
      },
      {
        path: '/contact-management/process-contacts',
        element: (
          <PrivateRoute>
            <ProcessContacts />
          </PrivateRoute>
        ),
        title: 'Contact Management - Add Pretile'
      },
      {
        path: '/contact-management/tiles/:id',
        element: (
          <PrivateRoute>
            <Profile type='tile' />
          </PrivateRoute>
        ),
        title: 'Contact Management - Tile'
      },
      {
        path: '/contact-management/tiles/:id/triangle-introduction',
        element: (
          <PrivateRoute>
            <TriangleIntroduction />
          </PrivateRoute>
        ),
        title: 'Contact Management - Tile Triangle Introduction'
      },
      {
        path: '/contact-management/upload-vcf',
        element: (
          <PrivateRoute>
            <UploadVCF />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/connections',
        element: (
          <PrivateRoute>
            <Connection />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/connections/new',
        element: (
          <PrivateRoute>
            <Request />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/connections/:id/outgoing',
        element: (
          <PrivateRoute>
            <Outgoing />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/connections/:id/incoming',
        element: (
          <PrivateRoute>
            <Incoming />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/introductions/:id',
        element: (
          <PrivateRoute>
            <IntroductionRespond />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/introductions/:id/review',
        element: (
          <PrivateRoute>
            <IntroductionReview />
          </PrivateRoute>
        )
      },
      {
        path: '/contact-management/pre-tags',
        element: (
          <PrivateRoute>
            <PreTags />
          </PrivateRoute>
        ),
        title: 'Manage Pre-tags'
      },
      {
        path: '/contact-management/pre-tags/:id/tags',
        element: (
          <PrivateRoute>
            <Tags profileType='PreTag' />
          </PrivateRoute>
        ),
        title: 'Manage Pre-tags Taglist'
      },
      {
        path: '/contact-management/generic-exchange-link',
        element: (
          <PrivateRoute>
            <GenericExchangeLink />
          </PrivateRoute>
        ),
        title: 'Create Generic Exchange Link'
      },
      {
        path: '/contact-management/generic-exchange-link/:token',
        element: (
          <PrivateRoute>
            <ExchangeLinkQR />
          </PrivateRoute>
        ),
        title: 'Created Generic Exchange Link'
      },
      {
        path: '/contact-management/generic-exchange-links',
        element: (
          <PrivateRoute>
            <GenericExchangeLinksManagement />
          </PrivateRoute>
        ),
        title: 'Manage Generic QR Links'
      },
    ]
  );
}

export default routes;
