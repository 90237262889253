import React, { lazy } from 'react';
import { isMobile } from 'react-device-detect';

import PrivateRoute from '@/privateRoute';

const ExchangeLinkDashboard = lazy(() => import('./ExchangeLinkDashboard'));
const ExchangeLinkEdit = lazy(() => import('./ExchangeLinkEdit'));
const ExchangeLinkExpirationDate = lazy(() =>
  import('./ExchangeLinkExpirationDate')
);

const routes = [
  {
    path: '/exchange-links/*',
    title: 'Exchange Link Dashboard',
    element: (
      <PrivateRoute>
        <ExchangeLinkDashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: ':id/edit',
        element: (
          <PrivateRoute>
            <ExchangeLinkEdit />
          </PrivateRoute>
        )
      },
      {
        path: ':id/edit/expiration-date',
        element: (
          <PrivateRoute>
            <ExchangeLinkExpirationDate />
          </PrivateRoute>
        )
      }
    ]
  }
];

if (isMobile) {
  routes.push(
    ...[
      {
        path: '/exchange-links/:id/edit',
        title: 'Exchange Link Dashboard - Edit',
        element: (
          <PrivateRoute>
            <ExchangeLinkEdit />
          </PrivateRoute>
        )
      },
      {
        path: '/exchange-links/:id/edit/expiration-date',
        title: 'Exchange Link Dashboard - Edit Expiration Date',
        element: (
          <PrivateRoute>
            <ExchangeLinkExpirationDate />
          </PrivateRoute>
        )
      }
    ]
  );
}

export default routes;
