import React from 'react';

import List from 'antd/es/list';
import ContactItem from './ContactItem';

const ListView = ({ contacts, childItem, onClick, onLongPress }) => {
  const handleClick = (e, contact) => onClick(e, contact);
  const handleLongPress = contact => onLongPress(contact);

  return (
    <List
      dataSource={contacts}
      itemLayout='horizontal'
      renderItem={(contact, index) => (
        <ContactItem
          onClick={e => handleClick(e, contact)}
          onLongPress={() => handleLongPress(contact)}
          item={contact}
          key={index}
        />
      )}
    />
  )
};

export default ListView;
