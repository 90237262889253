import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '@/lib/helpers/axiosInstance';

const MESH_BASE_URL = process.env.MESH_BASE_URL;

const createPayment = async payload => {
  const response = await axiosInstance().post(
    `${MESH_BASE_URL}/v1/init/payment`,
    payload,
  );

  return response.data;
};

export const useCreatePayment = () => {
  const queryClient = useQueryClient();

  return useMutation(createPayment, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user-subscription'] })
  });
};

const checkSubscription = async email => {
  const response = await axiosInstance().get(
    `${MESH_BASE_URL}/v1/check_subscription/${email}`
  );

  return response.data.subscription;
};

export const useCheckSubscription = email => {
  return useQuery({
    queryKey: ['user-subscription'],
    queryFn: () => checkSubscription(email),
    enabled: !!email
  });
};
