import React, { lazy } from 'react';

const ProcessContacts = lazy(() => import('.'));

const routes = [
  {
    path: '/process-contacts',
    element: <ProcessContacts />,
    title: 'Process Contacts'
  }
];

export default routes;
