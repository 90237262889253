import React, { lazy } from 'react';

const Begin = lazy(() => import('./Begin'));
const Welcome = lazy(() => import('./Welcome'));
const Roadmap = lazy(() => import('./Roadmap'));
const Commitments = lazy(() => import('./Commitments'));
const FAQ = lazy(() => import('./FAQ'));
const Goals = lazy(() => import('./Goals'));
const NDA = lazy(() => import('./NDA'));

export { Begin, Welcome, Roadmap, Commitments, FAQ, Goals, NDA };
