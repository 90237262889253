import React from 'react';
import { decamelizeKeys } from 'humps';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateTag } from '@/api/tags';

import Icon from '../Icon';
import InputField from '../InputField';

import { useToggle } from '@/hooks';

import { tagsEditValidation } from '@/validators';

import COLORS from '@/fixtures/colors';

import './styles.scss';

const TagEditOption = ({ onDelete = () => { }, tag = null }) => {
  const { mutate: updateTag } = useUpdateTag();

  const [isNameEditOpen, { setTrue: openNameEdit, setFalse: closeNameEdit }] =
    useToggle();

  const [
    isColorEditOpen,
    { setFalse: closeColorEdit, toggle: toggleColorEdit }
  ] = useToggle();

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      tagName: tag.name
    },
    resolver: yupResolver(tagsEditValidation)
  });

  const handleUpdateTagName = data => {
    const payload = decamelizeKeys({
      tag: {
        color: tag.color,
        id: tag.id,
        name: data.tagName
      }
    });

    updateTag({ id: tag.id, payload });
    closeNameEdit();
  };

  const handleUpdateTagColor = color => {
    const payload = decamelizeKeys({
      tag: {
        color,
        id: tag.id,
        name: tag.name
      }
    });

    updateTag({ id: tag.id, payload });
    closeColorEdit();
  };

  return (
    <div className='tagedit-option'>
      <span className='material-symbols-outlined more'>more_vert</span>
      <div className='options' onClick={toggleColorEdit}>
        <div className='color' style={{ background: tag.color }}></div>
        <span className='material-symbols-outlined expand'>expand_more</span>
      </div>
      <div className='details'>
        <span className='label' onClick={openNameEdit}>
          {tag.name}
        </span>
        <span className='number'>{tag.pretiles_count}</span>
        {isNameEditOpen && (
          <form
            className='edit-label'
            onSubmit={handleSubmit(handleUpdateTagName)}
          >
            <InputField
              placeholder='enter label name...'
              inputType='default'
              name='tagName'
              register={register}
              error={errors.tagName}
            />
            <button type='submit' className='icontainer'>
              <span className='material-symbols-outlined check'>check</span>
            </button>
          </form>
        )}
      </div>
      <Icon name='delete' className='delete' onClick={() => onDelete(tag)} />
      {isColorEditOpen && (
        <div className='color-dropdown'>
          <div className='heading'>
            <div className='toggle' onClick={toggleColorEdit}>
              <div className='color' style={{ background: tag.color }} />
              <span className='material-symbols-outlined expand'>
                expand_more
              </span>
            </div>
            <span className='label'>{tag.name}</span>
            <span
              className='material-symbols-outlined close'
              onClick={closeColorEdit}
            >
              close
            </span>
          </div>
          <div className='options'>
            {COLORS.map(color => (
              <div
                className='color'
                style={{ backgroundColor: color }}
                onClick={() => handleUpdateTagColor(color)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

TagEditOption.propTypes = {
  onDelete: PropTypes.func,
  tag: PropTypes.object
};

export default TagEditOption;
