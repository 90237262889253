import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useLongPress } from 'use-long-press';

import '../styles/BusinessCardView.scss';

const DefaultView = ({ item }) => {
  return (
    <div className='default-view'>
      <h1 className='name'>
        {item.first_name} {item.last_name}
      </h1>
      <p>{item.title_position}</p>
      <p>{item.company}</p>
    </div>
  );
};

const BusinessCardItem = ({
  item,
  onClick,
  onLongPress,
  type,
  tagBorderColor
}) => {
  const className = clsx('business-card-item', isMobile && '-mobile');
  const handleLongPress = useLongPress(onLongPress, { threshold: 500 });
  const style = { backgroundColor: tagBorderColor };

  return (
    <div
      className={className}
      onClick={onClick}
      style={style}
      {...handleLongPress()}
    >
      <div className='content'>
        {item.card_photo_url ? (
          <img src={item.card_photo_url} />
        ) : (
          <DefaultView item={item} />
        )}
      </div>
    </div>
  );
};

export default BusinessCardItem;
