import React, { useContext } from 'react';
import { useDeleteExperience } from '@/api/experience';

import { Accordion, Icon, RichTextField, ButtonIcon } from '@/components';

import { ExperienceContext } from './ExperienceList';
import '../styles/ExperienceItem.scss';

const ExperienceItem = ({ experience }) => {
  const startDate = experience.start_date.replace('/', '-');
  const endDate = experience.end_date.replace('/', '-');

  const { mutate: deleteExperience } = useDeleteExperience();
  const { setActiveForm, setCurrentExperience, readOnly } =
    useContext(ExperienceContext);

  const onDelete = e => {
    e.stopPropagation();
    deleteExperience(experience.id);
  };

  const onEdit = e => {
    e.stopPropagation();
    setActiveForm(true);
    setCurrentExperience(experience);
  };

  return (
    <Accordion className='experience-item'>
      <Accordion.ListHeader>
        <div className='flex'>
          <div className='experience-item-stat'>
            <div className='circle' />
          </div>
          <div className='experience-item-header'>
            <div className='title'>
              <p>
                {experience.title}, {experience.company}
              </p>
            </div>

            <div className='date'>
              {startDate} - {endDate || 'Present'}
            </div>
          </div>
        </div>
      </Accordion.ListHeader>
      <Accordion.Body>
        {!readOnly && (
          <Accordion.Body.Actions>
            <ul>
              <li>
                <ButtonIcon
                  type='button'
                  icon='delete'
                  color='deep-sapphire'
                  onClick={onDelete}
                />
              </li>
              <li>
                <ButtonIcon
                  icon='edit_square'
                  color='deep-sapphire'
                  onClick={onEdit}
                />
              </li>
            </ul>
          </Accordion.Body.Actions>
        )}
        <RichTextField.Content content={experience.description} />
      </Accordion.Body>
    </Accordion>
  );
};

export default ExperienceItem;
