import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { Slate, withReact } from 'slate-react';
import { createEditor } from 'slate';

import Form from './SlateComponents/Form';
import MarkButton from './SlateComponents/MarkButton';
import BlockButton from './SlateComponents/BlockButton';

import './styles.scss';

const OPTIONS = {
  bold: <MarkButton format='bold' icon='format_bold' key='bold' />,
  italic: <MarkButton format='italic' icon='format_italic' key='italic' />,
  underline: (
    <MarkButton format='underline' icon='format_underlined' key='underline' />
  ),
  numberedList: (
    <BlockButton
      format='numbered-list'
      icon='format_list_numbered'
      key='numbered-list'
    />
  ),
  bulletedList: (
    <BlockButton
      format='bulleted-list'
      icon='format_list_bulleted'
      key='bulleted-list'
    />
  ),
  left: <BlockButton format='left' icon='format_align_left' key='left' />,
  center: (
    <BlockButton format='center' icon='format_align_center' key='center' />
  ),
  right: <BlockButton format='right' icon='format_align_right' key='right' />,
  justify: (
    <BlockButton format='justify' icon='format_align_justify' key='justify' />
  ),
  headingOne: (
    <BlockButton format='heading-one' icon='looks_one' key='heading-one' />
  ),
  headingTwo: (
    <BlockButton format='heading-two' icon='looks_two' key='heading-two' />
  )
};

const DEFAULT_CONTENT_VALUE = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
];

const RichTextField = ({
  name,
  control,
  placeholder,
  readOnly,
  options = [],
  onChange,
  maxLength
}) => {
  const [editor] = useState(() => withReact(createEditor()));

  return (
    <div className='rich-text-field'>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => (
          <Slate
            editor={editor}
            value={typeof value === 'object' ? value : DEFAULT_CONTENT_VALUE}
            onChange={onChange}
          >
            <Form
              placeholder={placeholder}
              readOnly={readOnly}
              maxLength={maxLength}
              editor={editor}
            />
            <div className='rich-text-actions'>
              {options.length > 0 ? (
                <div className='grouping'>
                  {options.map(option => OPTIONS[option])}
                </div>
              ) : (
                <>
                  <div className='grouping'>
                    <MarkButton format='bold' icon='format_bold' />
                    <MarkButton format='italic' icon='format_italic' />
                    <MarkButton format='underline' icon='format_underlined' />
                    <BlockButton
                      format='numbered-list'
                      icon='format_list_numbered'
                    />
                    <BlockButton
                      format='bulleted-list'
                      icon='format_list_bulleted'
                    />
                  </div>
                  <div className='grouping'>
                    <BlockButton format='left' icon='format_align_left' />
                    <BlockButton format='center' icon='format_align_center' />
                    <BlockButton format='right' icon='format_align_right' />
                    <BlockButton format='justify' icon='format_align_justify' />
                    <BlockButton format='heading-one' icon='looks_one' />
                    <BlockButton format='heading-two' icon='looks_two' />
                  </div>
                </>
              )}
            </div>
          </Slate>
        )}
      />
    </div>
  );
};

export default RichTextField;
