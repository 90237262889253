import React from 'react';
import { useLongPress } from 'use-long-press';

import Card from 'antd/es/card';
import Button from '@/components/Button';
import HexTile from '@/components/HexTile';
import Icon from '@/components/Icon';

import parseContact from '@/lib/helpers/parseContact';

import '../styles/ContactItem.scss';

const ContactItem = ({
  item,
  onAction,
  onClick,
  onLongPress = () => {},
  type = 'pretile'
}) => {
  const {
    firstName,
    middleName,
    lastName,
    suffix,
    titlePosition,
    company,
    avatarUrl,
  } = parseContact(type === 'pretile' ? item : item.tile);

  const fullName = `${firstName} ${middleName} ${lastName} ${suffix}`;

  const getPositionAndCompany = () => {
    if (!titlePosition && !company) return <></>;

    if (titlePosition && !company) return <span>{titlePosition}</span>;

    if (!titlePosition && company) return <span>{company}</span>;

    return (
      <>
        <span>{`${titlePosition}, `}&nbsp;</span>
        <span>{company}</span>
      </>
    );
  };

  const handleLongPress = useLongPress(onLongPress, { threshold: 500 });

  return (
    <li className='contact-list-item'>
      <Card size='small'>
        <div className='contact-item' onClick={onClick}  {...handleLongPress()}>
          <div className='img-container'>
            <HexTile badge={item?.tile?.business_logo_url}>
              <HexTile.Image src={avatarUrl} />
            </HexTile>
          </div>
          <div className='content'>
            <p className='name'>{fullName}</p>
            <hr />
            <div className='bottom'>{getPositionAndCompany()}</div>
          </div>
          {  !onAction ? 
            <div className='status -pretile'>&nbsp;</div> :
            <Button
              onClick={onAction}
              icon={<Icon className='icon' name='check' />}
            />
          }
        </div>
      </Card>
    </li>
  );
};

export default ContactItem;
