import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const hasToken = !!Cookies.get('token');

  if (hasToken) {
    return children;
  } else {
    return <Navigate to='/login' replace state={{ from: location }} />;
  }
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrivateRoute;
