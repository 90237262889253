import * as yup from "yup";

const signupValidation = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email Address is required")
    .lowercase(),
  password: yup
    .string()
    .matches(/(?=.{8})/, "Password must be at least 8 characters long")
    .matches(
      /(?=^.{0,255}$)/,
      "Password must be no more than 255 characters long"
    )
    .matches(/(?=.*[a-z])/, "Password must have at least 1 lower-cased letter")
    .matches(/(?=.*[A-Z])/, "Password must have at least 1 capital letter")
    .matches(/(?=.*[0-9])/, "Password must have at least 1 number")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "Contains at least one special symbol (e.g., !, @, #, $, %)")
    .required("Password is empty"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match")
});

export default signupValidation;
