import React, { useState } from 'react';
import { getImageUrl } from '@/lib/helpers/getImageUrl';

import { Button, Cropper, Icon, Loader } from '@/components';
import generalLoader from '../../assets/images/general-loader.gif';

import './styles.scss';

const IMAGE_CROPPER_TYPES = {
  avatar_url: {
    value: 'avatar',
    label: 'Avatar Preview'
  },
  card_photo_url: {
    value: 'card_photo',
    label: 'Card Preview'
  },
  business_logo_url: {
    value: 'business_logo',
    label: 'Logo Preview'
  }
};

const ImageCropper = ({
  image,
  type,
  onSubmit,
  isSubmitting,
  aspectRatio = 1 / 1,
  onClose
}) => {
  const [croppedImage, setCroppedImage] = useState(null);
  const handleBack = () => onClose();

  const onChange = imgData => {
    setCroppedImage(imgData);
  };

  const handleSave = async () => {
    try {
      const fetchedImage = await fetch(croppedImage);
      const imageBlob = await fetchedImage.blob();
      const imageFile = new File([imageBlob], 'image.jpg', {
        type: imageBlob.type
      });
      const payload = new FormData();

      payload.append(IMAGE_CROPPER_TYPES[type].value, imageFile);

      onSubmit(payload);
    } catch (error) {
      console.error('error', error);
    }
  };

  if (isSubmitting) return <Loader src={generalLoader} />;

  return (
    <div className='image-cropper'>
      <div className='cropper-container'>
        <Cropper
          aspectRatio={aspectRatio}
          src={getImageUrl(image)}
          onChange={onChange}
        />
      </div>
      {croppedImage && (
        <Cropper.Preview
          src={croppedImage}
          label={IMAGE_CROPPER_TYPES[type].label}
          type={type}
        />
      )}
      <div className='cta-buttons'>
        <Button
          icon={<Icon name='chevron_left' className='icon' />}
          size='large'
          className='cancel-btn'
          onClick={handleBack}
        >
          Cancel
        </Button>
        {croppedImage && (
          <Button
            icon={<Icon name='save' className='icon -small' />}
            size='large'
            onClick={handleSave}
          >
            Save Crop
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
