import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ToastProvider } from 'react-toast-notifications';

import ToastNotificationContainer from '@/components/ToastNotificationContainer';

const AppProvider = ({ children }) => (
  <Fragment>
    <ToastProvider
      autoDismiss={true}
      autoDismissTimeout={3000}
      components={{ Toast: ToastNotificationContainer }}
      placement='top-center'
    >
      {children}
    </ToastProvider>
  </Fragment>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
