import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.scss';

export const DropdownContext = createContext();

const Dropdown = ({ children, className, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropDownClassName = clsx(
    'dropdown',
    className,
    disabled ? '-inactive' : '-active'
  );

  const dropdownContextValue = {
    isOpen,
    setIsOpen,
    disabled
  };

  return (
    <DropdownContext.Provider value={dropdownContextValue}>
      <div className={dropDownClassName}>{children}</div>
    </DropdownContext.Provider>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired
};

export default Dropdown;
