import React from 'react';
import clsx from 'clsx';

import { Icon, ButtonIcon } from '@/components';

import KIND_TYPE from '@/fixtures/kindType';

const FLAG_SRC = 'https://purecatamphetamine.github.io/country-flag-icons/3x2/';

import './styles.scss';

const ContactField = ({ field, onClickAction, ...props }) => {
  const handleCopyAddress = value => {
    navigator.clipboard.writeText(value);
  };

  const getActionLink = (kind, value) => {
    const kindType = {
      language: value,
      call: `tel:${value}`,
      sms: `sms:${value}`
    };

    return kindType[kind] || `mailto:${value}`;
  };

  return (
    <div className='contact-field' {...props}>
      <div className='wrapper'>
        <div className='label'>
          <span>{field.label}</span>
        </div>
        <div className='inner'>
          <div className={clsx('value', field.country ? '-flag' : '')}>
            {field.country && (
              <img
                src={`${FLAG_SRC}${field.country}.svg`}
                alt={`${field.country}-flag`}
                className='flag'
              />
            )}
            <span>{field.value}</span>
          </div>
          <div className='action'>
            {field.kind === 'place' ? (
              <ButtonIcon
                icon={KIND_TYPE[field.kind]}
                onClick={() => handleCopyAddress(field.value)}
              />
            ) : (
              <a
                target='_blank'
                onClick={() => onClickAction(field.kind)}
                href={getActionLink(field.kind, field.value)}
              >
                <Icon name={KIND_TYPE[field.kind]} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactField;
