import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import clsx from 'clsx';

const ButtonIcon = ({
  className = '',
  color = 'white',
  hasBorder = false,
  hasBoxShadow = false,
  icon,
  type = 'button',
  fill = false,
  ...props
}) => {
  const buttonClassName = [
    'button-icon',
    `-c-${color}`,
    hasBorder ? '-has-border' : '',
    hasBoxShadow ? '-has-box-shadow' : '',
    className
  ].join(' ');

  return (
    <button className={buttonClassName} type={type} {...props}>
      <span className={clsx( 'material-symbols-outlined', fill && '-fill')}>{icon}</span>
    </button>
  );
};

ButtonIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  hasBorder: PropTypes.bool,
  hasBoxShadow: PropTypes.bool,
  icon: PropTypes.string.isRequired
};

export default ButtonIcon;
