import React, { lazy } from 'react';

import PrivateRoute from '@/privateRoute';

const Notifications = lazy(() => import('.'));
const NotificationList = lazy(() => import('./components/NotificationList'))

const routes = [
  {
    path: '/notifications',
    element: (
      <PrivateRoute>
        <Notifications />
      </PrivateRoute>
    ),
    title: 'Notifications',
    children: [
      {
        path: ':type',
        element: <NotificationList />
      },
    ]
  }
];

export default routes;
