import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Controller } from 'react-hook-form';

import Select from 'antd/es/select';
import Space from 'antd/es/space';

import './styles.scss';

const SelectField = ({
  className,
  control,
  name,
  options = [],
  children,
  ...props
}) => {
  const classNames = clsx('select', className);

  const renderOptions = () => {
    return options.map(option => {
      return (
        <Select.Option key={option.value} value={option.value}>
          <Space className='flex -space-between'>
            {option.label}
            {option.icon}
          </Space>
        </Select.Option>
      );
    });
  };

  return (
    <div className={classNames}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              size='large'
              className={clsx(field.value && '-selected')}
              {...field}
              {...props}
            >
              {children ? children : renderOptions()}
            </Select>
          );
        }}
      />
    </div>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default SelectField;
