import React from 'react';
import clsx from 'clsx';

import './styles.scss';

const ReturnBar = ({
  onClick = () => {},
  title = 'Return',
  className = '',
  icon = 'arrow_back_ios_new'
}) => {
  const classNames = clsx('return-bar', className);

  return (
    <div className={classNames}>
      <div className='action'>
        <button onClick={onClick}>
          <span className='material-symbols-outlined icon'>{icon}</span>
        </button>
      </div>
      {title && <span>{title}</span>}
    </div>
  );
};

export default ReturnBar;
