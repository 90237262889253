import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Cookies from 'js-cookie';

import { useGetCurrentSound, useUpdateCurrentSound } from '@/api/currentSound';
import { useGetCurrentView, useUpdateCurrentView } from '@/api/currentView';

import MenuGroup from './MenuGroup';

import {
  PLATFORM_SETTINGS,
  PROFILE_SETUP,
  SYNTHNODE_FUNCTIONS,
  TUTORIALS,
  ACCOUNT_SETTINGS
} from '../fixtures/links';
import SOUND from '@/fixtures/sound';
import VIEW from '@/fixtures/view';

import navPerson from '@/assets/icons/nav-person.svg';
import logoutIcon from '@/assets/icons/logout.svg';

import '../styles/Menu.scss';

const Menu = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const isAuthenticated = !!Cookies.get('token');

  const { data: currentSound } = useGetCurrentSound();
  const { data: currentView } = useGetCurrentView();
  const { mutate: setCurrentSound } = useUpdateCurrentSound();
  const { mutate: setCurrentView } = useUpdateCurrentView();

  const menuClassNames = clsx(
    'dropdown-menu',
    'menu',
    isOpen ? '-shown' : '-hidden'
  );

  const handleSoundChange = (sound = SOUND.on) => setCurrentSound(sound);

  const handleViewChange = (view = VIEW.grid) => {
    setCurrentView(view);
  };

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('userId');

    // TODO(Neil): consider removing alpha-id from localStorage
    // localStorage.removeItem('alpha-id');

    navigate('/');
  };

  return (
    <nav className={menuClassNames}>
      {isAuthenticated ? (
        <>
          <MenuGroup
            links={SYNTHNODE_FUNCTIONS}
            onClick={close}
            title='Synthnode Functions'
            type='main'
          />
          <MenuGroup
            links={PROFILE_SETUP}
            onClick={close}
            onChange={handleSoundChange}
            title='Profile Setup'
            currentOption={currentSound}
          />
          <MenuGroup
            links={PLATFORM_SETTINGS}
            onClick={close}
            onChange={handleViewChange}
            title='Platform Settings'
            currentOption={currentView}
          />
          <MenuGroup
            links={ACCOUNT_SETTINGS}
            onClick={close}
            title='Account Settings'
          />
        </>
      ) : (
        <nav className='login'>
          <Link to='/login' onClick={close}>
            Log-in
          </Link>
          <img src={navPerson} />
          <Link to='/v2/signup' onClick={close}>
            register
          </Link>
        </nav>
      )}
      <MenuGroup links={TUTORIALS} onClick={close} title='Help and Support' />
      {isAuthenticated && (
        <nav className='logout'>
          <p onClick={() => logout()}>Log-off from Synthnode</p>
          <img src={logoutIcon} />
        </nav>
      )}
      <div className='version'>
        <p>SYNTHNODE PLATFORM</p>
        <p>BUILD VERSION {process.env.VERSION}</p>
      </div>
    </nav>
  );
};

export default Menu;
