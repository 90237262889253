import browserOverride from '@/lib/helpers/browserOverride';

import SOUND from '@/fixtures/sound';
import VIEW from '@/fixtures/view';

import bell from '@/assets/icons/bell.svg';
import contactWhite from '@/assets/icons/head-contact-white.svg';
import lists from '@/assets/icons/lists.svg';
import lock from '@/assets/icons/lock.svg';
import person from '@/assets/icons/person.svg';
import tags from '@/assets/icons/tags.svg';
import walkthrough from '@/assets/icons/walkthrough.svg';
import email from '@/assets/icons/email.svg';
import createExchange from '@/assets/icons/create-exchange.svg';
import manageExchange from '@/assets/icons/manage-exchange.svg';
import viewProfile from '@/assets/icons/view-profile.svg';

export const SYNTHNODE_FUNCTIONS = [
  {
    icon: contactWhite,
    linkTo: '/contact-management',
    text: 'Contact Management'
  },
  {
    icon: createExchange,
    linkTo: browserOverride('/exchange-link'),
    text: 'Send Exchange Link'
  },
  {
    icon: manageExchange,
    linkTo: '/exchange-links',
    text: 'Manage Exchange Links'
  },
  {
    icon: createExchange,
    linkTo: '/contact-management/generic-exchange-link',
    text: 'Create Generic QR Link'
  },
  {
    icon: manageExchange,
    linkTo: '/contact-management/generic-exchange-links',
    text: 'Manage Generic QR Links'
  },
  {
    icon: tags,
    linkTo: browserOverride('/tags/edit'),
    text: 'Manage Tags'
  },
  {
    icon: tags,
    linkTo: '/contact-management/pre-tags',
    text: 'Manage Pre-tags'
  }
];

export const PROFILE_SETUP = [
  {
    // *NOTE: Temporary icon
    icon: walkthrough,
    linkTo: '/tutorial',
    text: 'Onboarding Tutorial'
  },
  {
    icon: person,
    linkTo: browserOverride('/profile/edit'),
    text: 'Edit Profile'
  },
  {
    icon: lists,
    linkTo: '/exchange-fields/edit',
    text: 'Edit Exchange Fields and Levels'
  },
  {
    icon: viewProfile,
    linkTo: browserOverride('/profile'),
    text: 'View Profile Preview'
  }
];

export const PLATFORM_SETTINGS = [
  {
    icon: contactWhite,
    text: 'Contacts Default Display',
    items: [
      {
        text: 'Grid View (Default)',
        value: VIEW.grid
      },
      {
        text: 'List View',
        value: VIEW.list
      },
      {
        text: 'Business Card View',
        value: VIEW.businessCard
      }
    ]
  },
  {
    icon: bell,
    text: 'Notifications',
    items: [
      {
        text: 'Sound ON',
        value: SOUND.on
      },
      {
        text: 'Sound OFF',
        value: SOUND.off
      }
    ]
  }
];

export const ACCOUNT_SETTINGS = [
  {
    icon: lock,
    linkTo: '/account/password',
    text: 'Change Password'
  },
  {
    icon: email,
    linkTo: '/account/email',
    text: 'Change Email'
  }
];

export const TUTORIALS = [
  {
    icon: walkthrough,
    linkTo: '/tutorials/welcome',
    text: 'Product Walkthrough / Tutorials'
  }
];
