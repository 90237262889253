import React, { useMemo } from 'react';

import HexTile from '@/components/HexTile';

import parseContact from '@/lib/helpers/parseContact';
import truncateName from '@/lib/helpers/truncateName';
import GRID_COLUMNS_MULTIPLIER from '@/fixtures/gridColumnsMultiplier';

const AvatarName = ({ contact }) => {
  const { firstName, lastName } = parseContact(contact?.tile ?? contact); // contact?.tile will be removed

  return (
    <svg viewBox='0 0 60 30' fill='white'>
      <text x='50%' y='50%' dominantBaseline='middle' textAnchor='middle'>
        <tspan x='50%' y='0' dominantBaseline='hanging'>
          {truncateName(firstName)}
        </tspan>
        <tspan x='50%' y='15' dominantBaseline='hanging'>
          {truncateName(lastName)}
        </tspan>
      </text>
    </svg>
  );
};

const Grid = ({
  className,
  contacts,
  maxCols = 6,
  readOnly = false,
  onClick,
  onLongPress,
  tagBorderColor = ''
}) => {
  if (!contacts || !contacts.length) return;

  const columns = maxCols;
  const rows = Math.ceil(contacts.length / columns);

  const handleClick = (event, contact) => {
    if (readOnly) return;
    onClick(contact);
  };

  const listGrid = useMemo(() => {
    let gridColumns = [];
    const [colMarginTop, colOverlap] = GRID_COLUMNS_MULTIPLIER[columns];
    for (let col = 0; col < columns; col++) {
      // add contact to each grid column
      let columnContacts = [];
      for (let row = 0; row <= rows; row++) {
        if (row * columns + col >= contacts.length) break;

        columnContacts.push(contacts[row * columns + col]);
      }

      const isColOdd = col % 2 === 1;
      const marginTop = isColOdd ? `${colMarginTop}%` : '';
      const left = `calc((-${col} * ${colOverlap}%) + (${col} * 2px))`;

      gridColumns.push(
        <HexTile.Column
          key={col}
          style={{
            width: columns === 1 ? '50%' : undefined,
            marginTop,
            left
          }}
        >
          {columnContacts.map((contact, idx) => {
            const imageUrl = contact?.tile?.avatar_url ?? contact.avatar_url;
            const handleLongPress = () => onLongPress(contact);

            return (
              <HexTile
                key={`${contact.id}${idx}`}
                disabled={readOnly}
                onClick={e => handleClick(e, contact)}
                onLongPress={handleLongPress}
                borderColor={tagBorderColor}
                badge={contact?.tile?.business_logo_url}
              >
                {imageUrl ? (
                  <HexTile.Image src={imageUrl} />
                ) : (
                  <AvatarName contact={contact} />
                )}
              </HexTile>
            );
          })}
        </HexTile.Column>
      );
    }

    return gridColumns;
  }, [contacts, columns, rows]);

  return (
    <HexTile.Container
      className={className}
      style={{
        paddingLeft: columns > 1 ? '12%' : ''
      }}
    >
      {listGrid}
    </HexTile.Container>
  );
};

export default Grid;
