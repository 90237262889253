const getFullName = (contact = {}) => {
  const {
    first_name = '',
    middle_name = '',
    last_name = '',
    suffix = ''
  } = contact;
  let fullName = [];

  if (first_name) fullName.push(first_name);
  if (middle_name) fullName.push(middle_name);
  if (last_name) fullName.push(last_name);
  if (suffix) fullName.push(suffix);

  return fullName.join(' ');
};

export default getFullName;
