import React from 'react';

import BusinessCard from './components/BusinessCard';
import Grid from './components/Grid';
import List from './components/List';

import VIEW from '@/fixtures/view';

const View = (props) => {
  const { displayView } = props;

  switch (displayView) {
    case VIEW.businessCard:
      return <BusinessCard {...props} />;
    case VIEW.grid:
      return <Grid {...props} />;
    default:
      return <List {...props} />;
  }
}

export default View;
