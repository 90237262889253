import format from 'date-fns/format';

const dateFormatter = (date = new Date()) => {
  const parsedDate = new Date(date);

  if (parsedDate <= 0) return 'Indefinite';

  return format(parsedDate, 'yyyy-MM-dd');
};

export default dateFormatter;
