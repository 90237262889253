import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { isBrowser } from 'react-device-detect';

import { Cropper as ReactCropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Icon } from '@/components';

import './styles.scss';

const Cropper = ({ aspectRatio, className, onChange, src, ...props }) => {
  const classNames = clsx('cropper', className, isBrowser && '-browser');
  const cropperRef = useRef(null);

  const onCrop = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    onChange(cropper.getCroppedCanvas().toDataURL());
  });

  const rotateImage = angle => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(angle);
  };

  const handleZoom = useCallback(e => {
    const imageElement = cropperRef.current;
    const cropper = imageElement?.cropper;

    if (cropper) {
      const imageData = cropper?.getImageData();
      const cropBoxData = cropper?.getCropBoxData();
      const limitWidth = cropBoxData.width * 0.75;
      const cropBoxRatio = limitWidth / imageData.naturalWidth;

      if (cropBoxRatio > e.detail.ratio) {
        e.preventDefault();
        return;
      }
    }
  }, []);

  return (
    <div className={classNames}>
      <ReactCropper
        aspectRatio={aspectRatio}
        initialAspectRatio={aspectRatio}
        crop={onCrop}
        ref={cropperRef}
        src={src}
        dragMode='move'
        zoom={handleZoom}
        minCropBoxHeight={50}
        minCropBoxWidth={50}
        {...props}
      />
      <div className='actions'>
        <Button onClick={() => rotateImage(-90)}>
          <Icon name='rotate_left' className='icon -small' />
        </Button>
        <Button onClick={() => rotateImage(90)}>
          <Icon name='rotate_right' className='icon -small' />
        </Button>
      </div>
    </div>
  );
};

export default Cropper;
