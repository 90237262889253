import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useCreateExperience, useUpdateExperience } from '@/api/experience';
import { useToasts } from 'react-toast-notifications';
import { ExperienceContext } from './ExperienceList';
import {
  TextInput,
  DatePicker,
  RichTextField,
  ButtonIcon,
  Button,
  ToastNotification
} from '@/components';

import '../styles/ExperienceForm.scss';

const getDefaultDate = (date, isEndDate = false) => {
  if (!date && isEndDate) return null;
  const defaultDate = dayjs(date);

  return defaultDate.isValid() ? defaultDate : '';
};

const ExperienceForm = () => {
  const { mutate: createExperience, isLoading } = useCreateExperience();
  const { mutate: updateExperience } = useUpdateExperience();
  const { addToast } = useToasts();
  const { currentExperience, setCurrentExperience, setActiveForm } =
    useContext(ExperienceContext);
  const [experience, _setExperience] = useState(currentExperience);
  const [isShowEndDatePicker, setIsShowEndDatePicker] = useState(false);
  const [expMessage, setExpMessage] = useState('');
  const defaultStartDate = getDefaultDate(experience?.start_date);
  const defaultEndDate = getDefaultDate(experience?.end_date, true);

  const defaultValues = {
    company: experience?.company || '',
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    title: experience?.title || '',
    description: experience?.description || ''
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues
  });

  const onSubmit = data => {
    if (isLoading) return;
    const startDate = dayjs(data.startDate).format('YYYY/MM');
    const endDate = data.endDate ? dayjs(data.endDate).format('YYYY/MM') : '';

    const payload = {
      experience: {
        company: data.company,
        title: data.title,
        description: expMessage,
        start_date: startDate,
        end_date: endDate
      }
    };
    const requestOptions = {
      onSuccess: _response => {
        reset();
        setActiveForm(false);
        setCurrentExperience(null);
      },
      onError: err => {
        const errorMessage = err.response.data.errors[0];
        addToast(
          <ToastNotification
            type='Error'
            variant='error'
            icon='exclamation'
            message={errorMessage}
          />
        );
      }
    };

    if (!experience) {
      createExperience(payload, requestOptions);
    } else {
      updateExperience({ id: experience.id, payload }, requestOptions);
    }
  };

  const renderPresent = () => {
    const handlePresentClick = () => {
      setIsShowEndDatePicker(false);
      setValue('endDate', '');
    };

    return (
      <Button className='m-auto' onClick={handlePresentClick}>
        Present
      </Button>
    );
  };

  const onCancel = () => {
    reset();
    setCurrentExperience(null);
    setActiveForm(false);
    setExpMessage('');
  };

  return (
    <form className='experience-form' onSubmit={handleSubmit(onSubmit)}>
      <p>Title</p>
      <TextInput
        control={control}
        name='title'
        placeholder='Head of Marketing'
      />
      <p>Company</p>
      <TextInput control={control} name='company' placeholder='IBM' />
      <div className='date'>
        <p>Start Date</p>
        <p>End Date</p>
      </div>
      <div className='flex -space-between'>
        <DatePicker
          name='startDate'
          control={control}
          placeholder='Select Date'
        />
        <DatePicker
          name='endDate'
          control={control}
          placeholder='Present'
          showToday={false}
          onChange={date => {
            setIsShowEndDatePicker(false);
            setValue('endDate', date);
          }}
          onClick={() => setIsShowEndDatePicker(true)}
          open={isShowEndDatePicker}
          renderExtraFooter={renderPresent}
        />
      </div>
      <p>Description</p>
      <RichTextField
        control={control}
        name='description'
        onChange={value => setExpMessage(value)}
      />
      <div className='actions flex -end'>
        <ButtonIcon icon='clear' onClick={onCancel} color='blue-haze' />
        <ButtonIcon icon='save' type='submit' color='deep-sapphire' />
      </div>
    </form>
  );
};

export default ExperienceForm;
