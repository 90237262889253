import Cookies from 'js-cookie';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import axiosInstance from '@/lib/helpers/axiosInstance';

export const signIn = async payload => {
  const response = await axiosInstance().post('/users/sign_in', payload);

  return response;
};

export const signUp = async payload => {
  const response = await axiosInstance().post('/users', payload);

  return response;
};

export const signUpV2 = async payload => {
  const response = await axiosInstance().post('/v2/users', payload);

  return response;
};

const getUser = async () => {
  const userId = Cookies.get('userId');
  const response = await axiosInstance().get(`/api/v1/users/${userId}`);

  return response.data;
};

export const useGetUser = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: getUser
  });

  return { data, isLoading };
};

const getUsers = async () => {
  const response = await axiosInstance().get(`/api/v1/users`);

  return response.data;
};

export const useGetUsers = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers
  });

  return { data, isLoading };
};

const updateProfile = async ({ id, payload }) => {
  const response = await axiosInstance().put(`/api/v1/users/${id}`, payload);

  return response.data;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(updateProfile, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const uploadProfilePhoto = async ({ id, payload }) => {
  const response = await axiosInstance().put(
    `/api/v1/users/${id}/upload_avatar`,
    payload
  );

  return response.data;
};

export const useUploadProfilePhoto = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadProfilePhoto, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const uploadProfileCard = async ({ id, payload }) => {
  const response = await axiosInstance().put(
    `/api/v1/users/${id}/upload_card_photo`,
    payload
  );

  return response.data;
};

export const useUploadProfileCard = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadProfileCard, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const cropImage = async ({ id, type, data }) => {
  const IMAGE_TYPE = {
    avatar_url: 'upload_avatar',
    card_photo_url: 'upload_card_photo',
    business_logo_url: 'upload_business_logo'
  };

  const cropUrl = IMAGE_TYPE[type];
  const response = await axiosInstance().put(
    `/api/v1/users/${id}/${cropUrl}`,
    data
  );

  return response.data;
};

export const useCropImage = () => {
  const queryClient = useQueryClient();

  return useMutation(cropImage, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const uploadBusinessLogo = async ({ id, data }) => {
  const response = await axiosInstance().put(
    `/api/v1/users/${id}/upload_business_logo`,
    data
  );

  return response.data;
};

export const useUploadBusinessLogo = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadBusinessLogo, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const changePassword = async payload => {
  const response = await axiosInstance().put(`/api/v1/users/password`, payload);

  return response.data;
};

export const useChangePassword = () => {
  const queryClient = useQueryClient();

  return useMutation(changePassword, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

const changeEmail = async payload => {
  const response = await axiosInstance().put(`/api/v1/users/email`, payload);

  return response.data;
};

export const useChangeEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(changeEmail, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
};

export const getStripeCustomer = async () => {
  const response = await axiosInstance().get('/api/v1/users/customer');

  return response.data.customer;
};
