import React from 'react';

import Layout from 'antd/es/layout';
import Button from '../Button';
const { Content } = Layout;

import './styles.scss';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Layout>
      <Content className='error-fallback'>
        <h1 className='title'>Error 500</h1>
        <p className='subtitle'>Something went wrong</p>
        <Button onClick={resetErrorBoundary}>Refresh the page</Button>
      </Content>
    </Layout>
  );
};

export default ErrorFallback;
