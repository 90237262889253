import React, { createContext, useEffect, useState } from 'react';

import { Accordion, Button, ButtonIcon, Icon } from '@/components';
import { useGetSkills, useUpdateSkillsPositions } from '@/api/skill';
import SkillForm from './SkillForm';
import SkillItem from './SkillItem';
import { ReactSortable } from 'react-sortablejs';

export const SkillContext = createContext();

const SkillList = ({ readOnly = true, userId, isActive = false }) => {
  const [activeForm, setActiveForm] = useState(false);
  const [sortableMode, setSortableMode] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [sortedSkills, setSortedSkills] = useState([]);

  const { data: skills } = useGetSkills(userId);

  const { mutate: updateSkillsPositions } = useUpdateSkillsPositions();

  const skillContextValue = {
    activeForm,
    setActiveForm,
    currentSkill,
    setCurrentSkill,
    readOnly
  };

  useEffect(() => {
    if (skills) {
      setSortedSkills(skills);
    }
  }, [skills]);

  const handleOnClickAdd = () => setActiveForm(true);

  const handleDragEnd = () => {
    const payload = {
      skills: {
        skill_ids: sortedSkills.map(tag => tag.id),
        skill_positions: sortedSkills.map((_tag, idx) => idx)
      }
    };

    updateSkillsPositions({ payload });
  };

  const renderList = () => {
    if (!skills) return <></>;

    return skills.map(skill => <SkillItem key={skill.id} skill={skill} />);
  };

  const renderSortableList = () => {
    if (!skills) return <></>;

    return (
      <ReactSortable
        list={sortedSkills}
        setList={setSortedSkills}
        animation={200}
        handle='.drag-handle'
      >
        {sortedSkills.map(skill => (
          <div onDragEnd={handleDragEnd}>
            <SkillItem withHandle key={skill.id} skill={skill} />
          </div>
        ))}
      </ReactSortable>
    );
  };

  return (
    <SkillContext.Provider value={skillContextValue}>
      {(!!skills?.length || !readOnly) && (
        <Accordion className='skill-list' active={isActive}>
          <Accordion.ListHeader>Skillset</Accordion.ListHeader>
          <Accordion.Body>
            {!readOnly && (
              <Accordion.Body.Actions>
                <ul>
                  <li>
                    {sortableMode ? (
                      <Icon
                        name='check'
                        onClick={() => setSortableMode(false)}
                      />
                    ) : (
                      <Icon
                        name='swap_vert'
                        onClick={() => setSortableMode(true)}
                      />
                    )}
                  </li>
                  <li>
                    <ButtonIcon
                      type='button'
                      icon='add'
                      color='deep-sapphire'
                      onClick={handleOnClickAdd}
                    />
                  </li>
                </ul>
              </Accordion.Body.Actions>
            )}
            {activeForm && <SkillForm />}
            {sortableMode ? renderSortableList() : renderList()}
          </Accordion.Body>
        </Accordion>
      )}
    </SkillContext.Provider>
  );
};

export default SkillList;
