import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '@/lib/helpers/axiosInstance';

const getTags = async () => {
  const response = await axiosInstance().get('/api/v1/tags');

  return response.data.tags;
};

export const useGetTags = () => {
  return useQuery({
    queryKey: ['tags'],
    queryFn: getTags
  });
};

const createTags = async data => {
  const response = await axiosInstance().post('/api/v1/tags', data);

  return response.data;
};

export const useCreateTags = () => {
  const queryClient = useQueryClient();

  return useMutation(createTags, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tags'] })
  });
};

const updateTag = async ({ id, payload }) => {
  const response = await axiosInstance().put(`/api/v1/tags/${id}`, payload);

  return response.data;
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  return useMutation(updateTag, {
    onMutate: async ({ id, payload }) => {
      queryClient.cancelQueries({ queryKey: ['tags'] });

      const updatedTag = {
        id,
        ...payload.tag
      };

      const cachedTags = queryClient.getQueryData(['tags']);
      const previousTags = cachedTags.filter(tag => tag.id !== id);

      queryClient.setQueryData(
        ['tags'],
        [updatedTag, ...previousTags]
      );

      return { cachedTags };
    },
    onError: (_error, _noteData, context) => {
      queryClient.setQueryData(['tags'], context.cachedTags);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    }
  });
};

const deleteTag = async id => {
  const response = await axiosInstance().delete(`/api/v1/tags/${id}`);

  return response.data;
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteTag, {
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tags'] })
  });
};
