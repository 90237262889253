import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '@/lib/helpers/axiosInstance';
import { orderBy } from 'lodash';

const getExperiences = async userId => {
  const url = `/api/v1/experiences?user_id=${userId}`;
  const { data } = await axiosInstance().get(url);

  const sortedData = orderBy(
    data.experiences,
    [e => (e.end_date === '' ? '9999/99' : e.end_date), 'start_date'],
    ['desc', 'desc']
  );
  return sortedData || [];
};

export const useGetExperiences = userId => {
  return useQuery({
    queryKey: ['experiences'],
    queryFn: () => getExperiences(userId)
  });
};

const createExperience = async payload => {
  const response = await axiosInstance().post('/api/v1/experiences', payload);

  return response.data;
};

export const useCreateExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(createExperience, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
  });
};

const updateExperience = async ({ id, payload }) => {
  const response = await axiosInstance().put(
    `/api/v1/experiences/${id}`,
    payload
  );

  return response.data;
};

export const useUpdateExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(updateExperience, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
  });
};

const deleteExperience = async id => {
  const response = await axiosInstance().delete(`/api/v1/experiences/${id}`);

  return response.data;
};

export const useDeleteExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteExperience, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
  });
};
