import clsx from 'clsx';
import React from 'react';

const Button = ({ active, children, reversed, ...props }) => {
  return (
    <button
      className={clsx('format-button', active && '-active')}
      type='button'
      onClick={event => {
        event.stopPropagation();
      }}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
