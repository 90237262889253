import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './styles.scss';

const BackBar = ({ className = '', label = '', onClick = () => {} }) => {
  const classNames = clsx('back-bar', className);

  return (
    <button className={classNames} onClick={onClick}>
      <Icon name='navigate_before' className='icon' />
      <span className='label'>{label}</span>
    </button>
  );
};

BackBar.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default BackBar;
