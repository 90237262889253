import axiosInstance from '@/lib/helpers/axiosInstance';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const getNotes = async (notableId, notableType) => {
  const url = `/api/v1/notes?notable_id=${notableId}&notable_type=${notableType}`;
  const { data } = await axiosInstance().get(url);

  return data.notes || [];
};

export const useGetNotes = (notableId, notableType) => {
  return useQuery({
    queryKey: ['notes', notableId],
    queryFn: () => getNotes(notableId, notableType)
  });
};

const createNote = async payload => {
  const response = await axiosInstance().post('/api/v1/notes', payload);

  return response.data;
};

export const useCreateNote = notableId => {
  const queryClient = useQueryClient();

  return useMutation(createNote, {
    onMutate: async payload => {
      queryClient.cancelQueries({ queryKey: ['notes', notableId] });

      const createdNote = {
        date: '',
        ...payload.note
      };

      const cachedNotes = queryClient.getQueryData(['notes', notableId]);

      queryClient.setQueryData(
        ['notes', notableId],
        [createdNote, ...cachedNotes]
      );

      return { cachedNotes };
    },
    onError: (_error, _noteData, context) => {
      queryClient.setQueryData(['notes', notableId], context.cachedNotes);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', notableId] });
    }
  });
};

const updateNote = async ({ id, payload }) => {
  const response = await axiosInstance().put(`/api/v1/notes/${id}`, payload);

  return response.data;
};

export const useUpdateNote = notableId => {
  const queryClient = useQueryClient();

  return useMutation(updateNote, {
    onMutate: async ({ id, payload }) => {
      queryClient.cancelQueries({ queryKey: ['notes', notableId] });

      const updatedNote = {
        id,
        date: '',
        ...payload.note
      };

      const cachedNotes = queryClient.getQueryData(['notes', notableId]);
      const previousNotes = cachedNotes.filter(note => note.id !== id);

      queryClient.setQueryData(
        ['notes', notableId],
        [updatedNote, ...previousNotes]
      );

      return { cachedNotes };
    },
    onError: (_error, _noteData, context) => {
      queryClient.setQueryData(['notes', notableId], context.cachedNotes);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', notableId] });
    }
  });
};

const deleteNote = async id => {
  const response = await axiosInstance().delete(`/api/v1/notes/${id}`);

  return response.data;
};

export const useDeleteNote = pretileId => {
  const queryClient = useQueryClient();

  return useMutation(deleteNote, {
    onMutate: async id => {
      queryClient.cancelQueries({ queryKey: ['notes', pretileId] });

      const cachedNotes = queryClient.getQueryData(['notes', pretileId]);
      const filteredNotes = cachedNotes.filter(note => note.id !== id);

      queryClient.setQueryData(['notes', pretileId], filteredNotes);

      return { cachedNotes };
    },
    onError: (_error, _noteData, context) => {
      queryClient.setQueryData(['notes', pretileId], context.cachedNotes);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', pretileId] });
    }
  });
};
