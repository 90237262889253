import React from 'react';
import { useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { isMobile } from 'react-device-detect';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Layout from 'antd/es/layout';
import ConfigProvider from 'antd/es/config-provider';

import Router from '@/Router';

import Navbar from './components/Navbar';
import ErrorFallback from './components/ErrorFallback';

import './assets/styles/app.scss';
import './assets/styles/layout.scss';
import './assets/styles/helpers.scss';

const queryClient = new QueryClient();

const { Header } = Layout;

const App = () => {
  const { pathname } = useLocation();
  const isOnboarding = pathname.indexOf('/onboarding') === 0;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0c2e78',
          colorLink: '#0c2e78'
        }
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {isMobile ? (
            <div className='app-container'>
              <div className='route-container'>
                {!isOnboarding && <Navbar />}
                <Router />
              </div>
            </div>
          ) : (
            <Layout>
              {!isOnboarding && (
                <Header className='layout-header'>
                  <Navbar />
                </Header>
              )}
              <Layout
                className='layout-main'
                style={{ backgroundColor: '#fff' }}
              >
                <Router />
              </Layout>
            </Layout>
          )}
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
