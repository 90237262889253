import React, { useContext } from 'react';
import clsx from 'clsx';

import { ButtonIcon } from '@/components';

import { DropdownContext } from './Dropdown';

const Menu = ({ children, className, hasCloseButton = true }) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  const menuClassNames = clsx('menu', isOpen ? '-shown' : '-hidden', className);

  return (
    <div className={menuClassNames}>
      {children}
      {hasCloseButton && (
        <ButtonIcon
          icon='expand_less'
          color='white'
          className='close'
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default Menu;
