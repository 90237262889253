import { useCallback, useState } from "react";

const useToggle = (initialState = false) => {
  const [boolean, setBoolean] = useState(!!initialState);

  const setTrue = useCallback(() => setBoolean(true), []);
  const setFalse = useCallback(() => setBoolean(false), []);
  const toggle = useCallback(() => setBoolean((boolean) => !boolean), []);

  return [boolean, { setTrue, setFalse, toggle }];
};

export default useToggle;
