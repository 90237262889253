import PropTypes from "prop-types";
import React from "react";
import "./styles.scss";

/**
 * TODOs:
 *  - rename component
 */

const DeleteConfirmation = ({
  mainQueryInfo,
  data,
  secondaryQueryInfo,
  onCancel,
  cancelIcon,
  onConfirm,
  confirmIcon
}) => {
  return (
    <div className="delete-confirmation">
      <p className="confirmation-query">{mainQueryInfo}</p>
      {data && <div className="data">{data}</div>}
      {secondaryQueryInfo && (
        <p className="additional-info">
          <i>{secondaryQueryInfo}</i>
        </p>
      )}
      <div className="cta-buttons">
        <button className="cancel-button" type="button" onClick={onCancel}>
          Cancel
          {cancelIcon && (
            <span className="material-symbols-outlined delete icon">
              {cancelIcon}
            </span>
          )}
        </button>
        <button className="confirm-button" type="button" onClick={onConfirm}>
          <span>Confirm</span>
          {confirmIcon && (
            <span className="material-symbols-outlined delete icon">
              {confirmIcon}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

DeleteConfirmation.propTypes = {
  mainQueryInfo: PropTypes.string,
  data: PropTypes.node,
  secondaryQueryInfo: PropTypes.string,
  onCancel: PropTypes.func,
  cancelIcon: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmIcon: PropTypes.string
};

DeleteConfirmation.defaultProps = {
  mainQueryInfo: "",
  data: null,
  secondaryQueryInfo: "",
  onCancel: () => {},
  cancelIcon: "",
  onConfirm: () => {},
  confirmIcon: ""
};

export default DeleteConfirmation;
