import React from 'react';

import PrivateRoute from '@/privateRoute';
import {
  Tutorial,
  ValuePropositions,
  ProfileBasic,
  ProfileFieldsAndLevels,
  ProfileProfessionalHistoryAndSkillsets,
  ExchangeLinkContactInfo,
  ExchangeLinkDashboard,
  ContactManagementDashboard,
  ContactManagementContact,
  DynamicFeatureNotes,
  DynamicFeatureTags,
  DynamicFeatureMultiSelect,
  DynamicFeatureContactLogs
} from '.';

const routes = [
  {
    path: '/tutorial',
    title: 'Tutorial',
    element: (
      <PrivateRoute>
        <Tutorial />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'value-propositions',
        title: 'Tutorial - The Three Value Propositions',
        element: (
          <PrivateRoute>
            <ValuePropositions />
          </PrivateRoute>
        )
      },
      {
        path: 'profile-basic',
        title: 'Tutorial - Profile set up: Basic Profile',
        element: (
          <PrivateRoute>
            <ProfileBasic />
          </PrivateRoute>
        )
      },
      {
        path: 'profile-fields-and-levels',
        title: 'Tutorial - Profile set up: Setting up Fields and Levels',
        element: (
          <PrivateRoute>
            <ProfileFieldsAndLevels />
          </PrivateRoute>
        )
      },
      {
        path: 'profile-professional-history-and-skillsets',
        title:
          'Tutorial - Profile set up: Setting up Professional history and Skillsets',
        element: (
          <PrivateRoute>
            <ProfileProfessionalHistoryAndSkillsets />
          </PrivateRoute>
        )
      },
      {
        path: 'exchange-link-contact-info',
        title: 'Tutorial - Exchange Links: Exchanging Contact information',
        element: (
          <PrivateRoute>
            <ExchangeLinkContactInfo />
          </PrivateRoute>
        )
      },
      {
        path: 'exchange-link-dashboard',
        title:
          'Tutorial - Exchange Link Dashboard: Controlling exchanged information',
        element: (
          <PrivateRoute>
            <ExchangeLinkDashboard />
          </PrivateRoute>
        )
      },
      {
        path: 'contact-management-dashboard',
        title: 'Tutorial - Contact Management Dashboard',
        element: (
          <PrivateRoute>
            <ContactManagementDashboard />
          </PrivateRoute>
        )
      },
      {
        path: 'contact-management-contact',
        title: 'Tutorial - Creating and Editing a Contact',
        element: (
          <PrivateRoute>
            <ContactManagementContact />
          </PrivateRoute>
        )
      },
      {
        path: 'dynamic-feature-notes',
        title: 'Tutorial - Dynamic Feature: Notes',
        element: (
          <PrivateRoute>
            <DynamicFeatureNotes />
          </PrivateRoute>
        )
      },
      {
        path: 'dynamic-feature-tags',
        title: 'Tutorial - Dynamic Feature: Tags',
        element: (
          <PrivateRoute>
            <DynamicFeatureTags />
          </PrivateRoute>
        )
      },
      {
        path: 'dynamic-feature-multi-select',
        title: 'Tutorial - Contact Multiselect Feature',
        element: (
          <PrivateRoute>
            <DynamicFeatureMultiSelect />
          </PrivateRoute>
        )
      },
      {
        path: 'dynamic-feature-contact-logs',
        title: 'Tutorial - Dynamic Feature: Contact Logs',
        element: (
          <PrivateRoute>
            <DynamicFeatureContactLogs />
          </PrivateRoute>
        )
      }
    ]
  }
];

export default routes;
