import HexTile from './HexTile';
import Container from './Container';
import Column from './Column';
import Image from './Image';

HexTile.Container = Container;
HexTile.Column = Column;
HexTile.Image = Image;

export default HexTile;
