import React, { lazy } from 'react';
import PublicRoute from '@/publicRoute';

const ExchangeProfile = lazy(() => import('.'));

const routes = [
  {
    path: '/exchange-profile/:token',
    element: (
      <PublicRoute>
        <ExchangeProfile />
      </PublicRoute>
    ),
    title: 'Exchange Profile'
  }
];

export default routes;
