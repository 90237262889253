import React from 'react';

import Upload from 'antd/es/upload';

import './styles.scss'

const DUMMY_REQUEST = ({ onSuccess }) => {
  // AntD Upload has a default function
  // for uploading and doing a post request,
  // we don't need that, so we need to
  // to replace the default function to this
  onSuccess('ok');
};

const UploadFile = ({
  onChange = () => {},
  accept = '',
  children,
  ...props
}) => {
  return (
    <Upload
      onChange={onChange}
      accept={accept}
      showUploadList={false}
      customRequest={DUMMY_REQUEST}
      {...props}
    >
      {children}
    </Upload>
  );
};

export default UploadFile;
