import View from './View';
import BusinessCard from './components/BusinessCard';
import Grid from './components/Grid';
import List from './components/List';

View.BusinessCard = BusinessCard;
View.Grid = Grid;
View.List = List;

export default View;
