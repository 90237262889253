import React from 'react';
import PropTypes from 'prop-types';
import AntButton from 'antd/es/button';
import clsx from 'clsx';

import './styles.scss';

const Button = ({
  type = 'button',
  onClick,
  className = '',
  antType = 'primary',
  children,
  ...props
}) => {
  const btnClass = clsx('btn', className);

  return (
    <AntButton
      className={btnClass}
      type={antType}
      htmlType={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </AntButton>
  );
};

Button.propTypes = {
  antType: PropTypes.oneOf(['primary', 'default', 'link', 'text']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit'])
};

export default Button;
