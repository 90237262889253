import React from 'react';

import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import Button from './Button';

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <span className='material-symbols-outlined'>{icon}</span>
    </Button>
  );
};

export default MarkButton;
