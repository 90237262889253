import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SOUND from '@/fixtures/sound';

const getCurrentSound = async () => {
  const data = await new Promise(resolve => {
    const item = localStorage.getItem('currentSound') || JSON.parse(SOUND.on);
    resolve(item);
  });

  return data;
};

export const useGetCurrentSound = () => {
  return useQuery({
    queryKey: ['currentSound'],
    queryFn: getCurrentSound,
    initialData: SOUND.on
  });
};

const updateCurrentSound = async data => {
  const response = await new Promise(resolve => {
    localStorage.setItem('currentSound', data);
    resolve(data);
  });

  return response;
};

export const useUpdateCurrentSound = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCurrentSound, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['currentSound'] })
  });
};
