import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { getImageUrl } from '@/lib/helpers/getImageUrl';

import './styles.scss';

const VirtualCard = ({ imageUrl = '' }) => (
  <div className={clsx('virtual-card', imageUrl ? 'wrapper' : 'default')}>
    {imageUrl ? (
      <img src={getImageUrl(imageUrl)} alt='' className='-preview' />
    ) : (
      <p>Business Card Photo</p>
    )}
  </div>
);

VirtualCard.propTypes = {
  imageUrl: PropTypes.string
};

export default VirtualCard;
