import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Body = ({ children, className = '' }) => (
  <div className={clsx('body', className)}>{children}</div>
);

Body.propTypes = {
  className: PropTypes.string
};

export default Body;
