import Accordion from './Accordion';
import Header from './Header';
import ListHeader from './ListHeader';
import Body from './Body';
import Actions from './Actions';

Accordion.ListHeader = ListHeader;
Accordion.Header = Header;
Accordion.Body = Body;
Accordion.Body.Actions = Actions;

export default Accordion;
