import React, { useMemo } from 'react';
import { isBrowser } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Cookies from 'js-cookie';

import { useCropImage, useGetUser } from '@/api/users';

import ToastNotification from '@/components/ToastNotification';
import ImageCropper from '@/components/ImageCropper';

import { getImageUrl } from '@/lib/helpers/getImageUrl';
import { BUSINESS_CARD_ASPECT_HEIGHT } from '@/fixtures/businessCardAspectRatio';
import { BADGE_ASPECT_HEIGHT } from '@/fixtures/badgeAspectRatio';

const CropAvatar = () => {
  const userId = Cookies.get('userId');
  const navigate = useNavigate();

  const { data } = useGetUser();
  const { mutate: cropImage, isLoading: isUploading } = useCropImage();
  const { addToast } = useToasts();
  const {
    state: { image }
  } = useLocation();

  const aspectRatio = useMemo(() => {
    switch (image) {
      case 'business_logo':
        return 1 / BADGE_ASPECT_HEIGHT;
      case 'card_photo_url':
        return 1 / BUSINESS_CARD_ASPECT_HEIGHT;
      default:
        return 1 / 1;
    }
  }, [image]);
  const link = isBrowser ? '/contact-management/profile/edit' : '/profile/edit';

  const handleCropImage = payload => {
    cropImage(
      { id: userId, type: image, data: payload },
      {
        onSuccess: () => {
          addToast(
            <ToastNotification
              type='Success'
              message='Your image has been successfully cropped!'
            />
          );
          navigate(link);
        }
      }
    );
  };

  return (
    <ImageCropper
      image={getImageUrl(data?.user[image])}
      type={image}
      onSubmit={handleCropImage}
      isSubmitting={isUploading}
      onClose={() => navigate(link)}
      aspectRatio={aspectRatio}
    />
  );
};

export default CropAvatar;
