import axios from 'axios';
import Cookies from 'js-cookie';

export default () => {
  // const baseURL =
  //   "https://synthnode-api-staging.tu84cnsfmmor2.us-west-2.cs.amazonlightsail.com";
  const baseURL = process.env.REACT_APP_API_URL;
  const token = Cookies.get('token');

  let headers = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers
  });

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const configUrl = error.response.config.url;
      if (
        error.response.status === 401 &&
        (configUrl !== '/users/sign_in' && configUrl !== '/v2/users')
      ) {
        Cookies.remove('token');
        Cookies.remove('userId');

        window.location = `/login?redirect=login`;
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
