import React, { lazy } from 'react';

import PrivateRoute from '@/privateRoute';

const Subscription = lazy(() => import('.'));

const routes = [
  {
    path: '/subscription',
    title: 'Subscription',
    element: (
      <PrivateRoute>
        <Subscription />
      </PrivateRoute>
    )
  }
];

export default routes;
