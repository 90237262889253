import React from 'react';
import _ from 'lodash';

import { ButtonIcon, RichTextField } from '@/components';


const UserNote = ({
  noteMessage,
  noteBool,
  onClick,
  onChange,
  label,
  name,
  control,
}) => {
  return (
    <div className='profile-note-field'>
      <label className='generic-label'>{label}</label>
      {!noteBool ? (
        <div>
          <div className='note-field'>
            <div className='note-button-edit' onClick={e => onClick(e, name)}>
              <ButtonIcon icon='edit_square' color='deep-sapphire' />
            </div>
            <RichTextField.Content content={noteMessage} />
          </div>
        </div>
      ) : (
        <div className='note-field'>
          <div className='note-button-edit' onClick={onClick}>
            <ButtonIcon icon='clear' color='deep-sapphire' />
          </div>
          <div className='note-shadow-field'>
            <RichTextField
              control={control}
              name={name}
              placeholder='Enter note description.'
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNote;
