import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/MenuOption.scss';

const MenuOption = ({ icon, linkTo, onClick, text, type = 'default' }) => {
  return (
    <Link to={linkTo} onClick={onClick}>
      <div className='menu-option'>
        <div className={clsx('icon-container', type === 'main' && '-main')}>
          <img className='icon' src={icon} />
        </div>
        <p className='text'>{text}</p>
      </div>
    </Link>
  );
};

export default MenuOption;
