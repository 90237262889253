import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const token = Cookies.get('token');
  const location = useLocation();

  if (!token) {
    return children;
  } else {
    return <Navigate to='/' replace state={{ from: location }} />;
  }
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PublicRoute;
